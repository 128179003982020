import { List, Comment, Row, Col, PageHeader } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { IComment } from "../store/comments/types";

const Discussions = () => {
  const history = useHistory();
  const { onto_id } = useParams<{ onto_id: string }>();
  const [comments, setComments] = useState<IComment[]>([]);

  /**
   * Получаем список комментариев
   * Поддерживаем его актуальным при каждом монтировании компонена,
   * чтобы при добавлнии нового юзера сразу его видеть
   */
  useEffect(() => {
    (async () => {
      const comments = await axios(`/ontologies/${onto_id}/comments`);
      comments && setComments(comments.data);
    })();
  }, [onto_id]);

  return (
    <>
      <PageHeader onBack={() => history.goBack()} title="Обсуждение онтологии" />
      <Row style={{ padding: 24 }}>
        <Col span={24}>
          <List
            className="comment-list"
            itemLayout="horizontal"
            dataSource={comments}
            renderItem={(comment) => (
              <li>
                <Comment
                  author={
                    <a
                      rel="noreferrer"
                      href={`https://leader-id.ru/users/${comment.author_id}`}
                      target="_blank"
                    >
                      #{comment.author_id}
                    </a>
                  }
                  content={comment.text}
                  datetime={dayjs(comment.update_time).format("DD.MM.YYYY HH:mm")}
                />
              </li>
            )}
          />
        </Col>
      </Row>
    </>
  );
};

export default Discussions;
