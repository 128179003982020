import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export interface IQueryParams {
  [key: string]: string | string[] | undefined;
}
export function useQueryParams() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useState<URLSearchParams>();
  //const searchParams = new URLSearchParams(location.search);
  const [queryParams, setQueryParams] = useState<IQueryParams>(
    location.search
      .substr(1)
      .split("&")
      .map((item) => item.split("="))
      .reduce((url, [key, value]) => {
        if (!url.hasOwnProperty(key)) {
          url[key] = value;
        } else if (typeof url[key] === "string") {
          url[key] = [url[key].toString(), value];
        } else if (typeof url[key] === "object") {
          (url[key] as string[]).push(value);
        }
        return url;
      }, {} as { [key: string]: string | string[] })
  );

  const updateParam = (property: string, value: any) => {
    setQueryParams({ ...queryParams, [property]: value });
  };

  const removeParam = (property: string) => {
    const newParams = Object.assign({}, queryParams);
    delete newParams[property];
    setQueryParams(newParams);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setSearchParams(params);
  }, [location.search]);

  return {
    location,
    searchParams: searchParams && Object.fromEntries(searchParams),
    queryParams,
    updateParam,
    removeParam,
    setQueryParams,
  };
}
