import axios from "axios";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataSet, IdType, Network, Options } from "vis-network/standalone/esm/vis-network";
import { useHistory, useParams } from "react-router-dom";
import { Button, message, Modal, Select, Space } from "antd";

import { EventType } from "../types/EventType";
import { RootState } from "../store";
import { IScenario } from "../store/scenarios/types";
import { IPublication } from "../store/publications/types";
import { IEdge, IGroupAttrs, INode } from "../store/graphs/types";
import { INodeClass } from "../store/nodeclasses/types";
import { updateGraphs } from "../store/graphs/actions";
import { qsOntoParams } from "../helpers/qsOntoParams";
import { useQueryParams } from "../hooks/useQueryParams";
import { useUniqueArray } from "../hooks/useUniqueArray";
import { asyncLoadViewById } from "../services/views.service";
import { asyncLoadPresetById } from "../services/presets.service";
import { asyncLoadScenariosByOntoId } from "../services/scenarios.service";
import { asyncLoadGroupAttrs, asyncLoadNodeClasses } from "../services/group_attrs.service";

import VisibleStatusClass from "../components/VisibleStatusClass";
import VisNetwork from "../components/VisNetwork";
import ScenarioModal from "../components/ontologies/ScenarioModal";
import CommentsManager from "../components/comments/CommentsManager";
import SharedEdgePanel from "../components/shared/SharedEdgePanel";
import SharedNodePanel from "../components/shared/SharedNodePanel";
import Legend from "../components/legend/Legend";

const SharedScenario = () => {
  const { view_id } = useParams<{ view_id: string }>();
  const { queryParams, updateParam, removeParam, setQueryParams } = useQueryParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const refNetwork = useRef<Network | null>();
  const refNodes = useRef<DataSet | null>();
  const { comments, presets } = useSelector((state: RootState) => state);

  const [checkedNodes, addCheck, removeCheck] = useUniqueArray<string>();
  const [selectedScenario, setSelectedScenario] = useState<string>("");
  const [isVisibleScenarios, setIsVisibleScenarios] = useState(!queryParams.type);
  const [view, setView] = useState<IPublication>();
  const [options, setOptions] = useState<Options>();
  const [scenarios, setScenarios] = useState<IScenario[]>([]);
  const [tempDataNode, setTempDataNode] = useState<INode>();
  const [tempDataEdge, setTempDataEdge] = useState<IEdge>();
  const [groupAttrs, setGroupAttrs] = useState<IGroupAttrs[]>([]);
  const [nodeClasses, setNodeClasses] = useState<INodeClass[]>([]);

  const { nodes, edges } = useSelector((state: RootState) => state.graphs);

  // Получаем данные текущего вида
  const filteredNodeClasses = nodeClasses.filter((node) => node.onto_id === view?.model_id);

  /**
   * Получаем рефы visnetwork'a
   */
  const getNetwork = useCallback((network: Network) => {
    refNetwork.current = network;
  }, []);

  const getNodes = useCallback((nodes: DataSet) => {
    refNodes.current = nodes;
  }, []);

  /**
   * Фокусируемся на указанной ноде
   */
  const handleFocus = useCallback((node_id: IdType) => {
    refNetwork.current?.focus(node_id);
  }, []);

  /**
   * Делаем ноду выбранной
   */
  const handleSelectNode = useCallback((node_id: IdType) => {
    refNetwork.current?.selectNodes([node_id]);
  }, []);

  /**
   * Делаем ноду выбранной
   */
  const handleSelectEdge = useCallback((edge_id: IdType) => {
    refNetwork.current?.selectNodes([edge_id]);
  }, []);

  /**
   * Задаем события для VisNetwork
   */
  const events: EventType = useMemo(() => {
    return {
      select: (params: any) => {
        if (params.nodes.length > 0) {
          // Получаем его идентификатор
          const nodeId = params.nodes[0];
          // Ищем его в массиве
          const node = nodes.find((n) => n.id === nodeId);
          // Записываем во временные данные
          setTempDataNode(node);
        } else {
          // Получаем его идентификатор
          const edgeId = params.edges[0];
          // Ищем его в массиве
          const edge = edges.find((n) => n.id === edgeId);
          // Записываем во временные данные
          setTempDataEdge(edge);
        }
      },
    };
  }, [edges, nodes]);

  /**
   * Загружаем данные вида: параметры, сценарии, пресет
   */
  const getView = useCallback(async () => {
    // Получаем данные вида с сервера
    const view = await asyncLoadViewById(view_id);
    if (view) {
      // Записываем данные вида в стейт
      setView(view);
      // Получаем пресет для вида
      const preset = await asyncLoadPresetById(view.preset_id);
      preset && setOptions(preset.content);
      // Получаем список сценариев
      const scenarios = await asyncLoadScenariosByOntoId(view.model_id);
      scenarios && setScenarios(scenarios);
      // Получаем список групповых атрибутов
      const group_attrs = await asyncLoadGroupAttrs(view.model_id);
      group_attrs && setGroupAttrs(group_attrs);
      // Получаем классы узлов
      const node_classes = await asyncLoadNodeClasses();
      node_classes && setNodeClasses(node_classes);
    } else {
      message.error("Проблема с загрузкой параметров публикации");
    }
  }, [view_id]);

  // Запускаем загрузку данных при монтировании
  useEffect(() => {
    getView();
  }, [getView]);

  /**
   * Загружаем ноды при старте или изменении зависимостей
   */
  useEffect(() => {
    // Проверяем есть ли тип в параметрах
    if (!queryParams.type) return;
    // Выполняем асинхронный запрос
    (async () => {
      try {
        // Формируем поисковую строку
        const qs = qsOntoParams(queryParams.nodes, queryParams.hide, false);
        // Получаем данные с сервера
        const req = await axios(`/views/${view_id}/nodes?type=${queryParams.type}${qs}`);
        // Записываем данные в хранилище
        dispatch(updateGraphs(req.data.nodes, req.data.edges));
      } catch (error) {}
    })();
    // При размонтировании убираем лишнее
    return () => {
      // убираем панель информации о выбранной ноде
      setTempDataNode(undefined);
      setTempDataEdge(undefined);
      // удаляем информацию о нодах и связях
      dispatch(updateGraphs([], []));
    };
  }, [dispatch, queryParams, view_id]);

  /**
   * Обновляем url при изменении параметров
   */
  useEffect(() => {
    if (!queryParams.type) return;
    const qs = qsOntoParams(queryParams.nodes, queryParams.hide);
    history.replace(`/shared/${view_id}/scenario?type=${queryParams.type}${qs}`);
  }, [queryParams, history, view_id]);

  /**
   * Переключаем видимость классов
   */
  const handleToggleVisible = useCallback(
    (node_id: string) => {
      if (typeof queryParams.hide === "string") {
        if (queryParams.hide === node_id) {
          removeParam("hide");
        } else {
          updateParam("hide", [queryParams.hide, node_id]);
        }
      } else if (Array.isArray(queryParams.hide)) {
        // Если hide объект и содержит ид ноды, удаляем его
        if (queryParams.hide?.includes(node_id)) {
          const newHide = queryParams.hide.filter((h) => h !== node_id);
          updateParam("hide", newHide.length > 1 ? newHide : newHide[0]);
        } else {
          const newHide = queryParams.hide.concat(node_id);
          updateParam("hide", newHide);
        }
      } else {
        updateParam("hide", node_id);
      }
    },
    [updateParam, removeParam, queryParams]
  );

  /**
   * Активируем и центрируем узел или связь выбранную в комментариях
   */
  const handleSelectComment = useCallback(
    (model_item_id: string) => {
      // получаем узел комментария
      const findNodeByModelItemId = nodes.find((node) => node.model_node_id === model_item_id);
      // получаем связь комментариях
      const findEdgeByModelItemId = edges.find((edge) => edge.model_edge_id === model_item_id);
      // Если есть узел, синтезируем со списком старовых узлов
      if (findNodeByModelItemId?.id) {
        handleSelectNode(findNodeByModelItemId.id);
        handleFocus(findNodeByModelItemId.id);
      }
      // Если есть связь, фокусируемся на ней
      if (findEdgeByModelItemId?.id) {
        handleSelectEdge(findEdgeByModelItemId.id);
      }
    },
    [nodes, edges, handleSelectNode, handleFocus, handleSelectEdge]
  );

  /**
   * Изменение/Выбор сценария
   */
  const handleSelectScenario = useCallback(() => {
    setIsVisibleScenarios(false);
    const params = scenarios.find((scenario) => scenario.id === selectedScenario)?.params;
    if (params) {
      params && setQueryParams({ ...params, nodes: checkedNodes });
    }
  }, [scenarios, checkedNodes, selectedScenario, setQueryParams]);

  return (
    <>
      <Modal
        width={600}
        title="Выберите сценарий"
        visible={isVisibleScenarios}
        onOk={handleSelectScenario}
        okButtonProps={{
          disabled: checkedNodes.length === 0,
        }}
        cancelText={<></>}
      >
        <Select
          style={{ width: "100%", marginBottom: 24 }}
          value={selectedScenario}
          options={scenarios.map((scenario) => {
            return { label: scenario.name, value: scenario.id };
          })}
          onChange={(value: string) => setSelectedScenario(value)}
        />
        <ScenarioModal
          view={view}
          scenario={scenarios.find((s) => s.id === selectedScenario)}
          onSelectScenario={addCheck}
          onUnselectScenario={removeCheck}
        />
      </Modal>
      <div className="scenario">
        <Space wrap>
          <Button
            type="primary"
            onClick={() => {
              setIsVisibleScenarios(true);
              history.push(`/shared/${view_id}/scenario`);
            }}
          >
            Изменить сценарий
          </Button>
          {filteredNodeClasses.map((node) => (
            <VisibleStatusClass
              key={node.id}
              node={node}
              queryParams={queryParams}
              onToggle={handleToggleVisible}
            />
          ))}
        </Space>
      </div>
      {options && (
        <VisNetwork
          data={{ nodes, edges }}
          options={options}
          events={events}
          getNetwork={getNetwork}
          getNodes={getNodes}
        />
      )}
      {groupAttrs.length > 0 && <Legend group_attrs={groupAttrs} presets={presets} />}
      {view && (
        <CommentsManager
          onto_id={view.model_id}
          comments={comments}
          onSelect={handleSelectComment}
        />
      )}
      {tempDataEdge && (
        <SharedEdgePanel
          onFocus={handleFocus}
          tempDataEdge={tempDataEdge}
          setTempDataEdge={setTempDataEdge}
          onSelectComment={handleSelectComment}
        />
      )}
      {tempDataNode && (
        <SharedNodePanel
          tempDataNode={tempDataNode}
          onSelectComment={handleSelectComment}
          onFocus={handleFocus}
          setTempDataNode={setTempDataNode}
        />
      )}
    </>
  );
};

export default SharedScenario;
