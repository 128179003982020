import { Alert, Button, Form, Input } from "antd";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { asyncDeleteOntology } from "../../services/ontologies.service";
import { removeOntologies } from "../../store/ontologies/actions";
import { IOntology } from "../../store/ontologies/types";

type RemoveOntologyProps = {
  ontology: IOntology;
  onCancel: () => void;
};

const RemoveOntology: React.FC<RemoveOntologyProps> = ({ ontology, onCancel }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  /**
   * Подтверждаем удаление онтологии
   */
  const handleConfirmDelete = useCallback(async () => {
    // Получаем результат удаления с сервера
    const isDeleted = await asyncDeleteOntology(ontology.id);
    if (isDeleted) {
      // Удаляем из локального хранилища
      dispatch(removeOntologies(ontology.id));
      // Скрываем модальку
      onCancel();
    }
  }, [dispatch, onCancel, ontology.id]);

  /**
   * Отменяем ввод с помощью команды Ctrl+V
   * @param event Событие нажатия клавиши
   */
  const handleCancelPaste = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.metaKey && event.code === "KeyV") {
      event.preventDefault();
    }
  };

  return (
    <>
      <Alert
        message="Вы действительно хотите удалить все данные?"
        description="Если вы подтвердите удаление, будут удалены: виды, узлы, связи. БЕЗВОЗВРАТНО!"
        type="error"
        showIcon
      />
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Для подтверждения напишите название онтологии"
          rules={[{ required: true, message: "Напишите название онтологии" }]}
        >
          <Input onKeyDown={handleCancelPaste} onContextMenu={(e) => e.preventDefault()} />
        </Form.Item>
        <Form.Item shouldUpdate>
          {() => (
            <>
              <Button type="default" size="large" onClick={onCancel}>
                Отменить удаление
              </Button>{" "}
              <Button
                type="primary"
                size="large"
                danger
                disabled={form.getFieldValue("name") !== ontology.name}
                onClick={handleConfirmDelete}
              >
                Подтвердить удаление
              </Button>
            </>
          )}
        </Form.Item>
      </Form>
    </>
  );
};

export default RemoveOntology;
