import { Action, combineReducers } from "redux";
import { ThunkAction } from "redux-thunk";
import { appReducer } from "./app/reducer";
import { authReducer } from "./auth/reducer";
import { commentsReducer } from "./comments/reducer";
import { editorsReducer } from "./editors/reducer";
import { graphsReducer } from "./graphs/reducer";
import { nodeclassesReducer } from "./nodeclasses/reducer";
import { ontologiesReducer } from "./ontologies/reducer";
import { presetsReducer } from "./presets/reducer";
import { publicationsReducer } from "./publications/reducer";
import { scenariosReducer } from "./scenarios/reducer";
import { viewsReducer } from "./views/reducer";

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  presets: presetsReducer,
  ontologies: ontologiesReducer,
  graphs: graphsReducer,
  scenarios: scenariosReducer,
  nodeclasses: nodeclassesReducer,
  publications: publicationsReducer,
  editors: editorsReducer,
  comments: commentsReducer,
  views: viewsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default rootReducer;
