import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Checkbox, Form, Input, List, Select, Alert, message, Switch } from "antd";
import { List as VList, ListRowRenderer } from "react-virtualized";

import { RootState } from "../../store";
import { INode } from "../../store/graphs/types";
import { IOntology } from "../../store/ontologies/types";
import { addOntologies } from "../../store/ontologies/actions";
import { addView } from "../../store/views/actions";
import { asyncLoadNodeByOntologyId } from "../../services/nodes.service";
import { asyncCopyOntology } from "../../services/ontologies.service";

interface OntologyDuplicateProps {
  ontology: IOntology;
  onCompleted: () => void;
}

const OntologyDuplicate: React.FC<OntologyDuplicateProps> = ({ ontology, onCompleted }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const views = useSelector((state: RootState) => state.views);
  const [checkboxes, setCheckboxes] = useState<INode[]>([]);
  const [isVisibleViewsList, setIsVisibleViewsList] = useState(false);
  const [isVisibleNodesList, setIsVisibleNodesList] = useState(false);
  const [isCopying, setIsCopying] = useState(false);

  useEffect(() => {
    (async () => {
      if (!ontology) return;
      try {
        const checkboxes = await asyncLoadNodeByOntologyId(ontology.id);
        checkboxes && setCheckboxes(checkboxes.nodes);
      } catch (error) {}
    })();
  }, [ontology]);

  const handleFinish = useCallback(
    async (values) => {
      console.log("values :>> ", values);
      // Включаем лоадер на кнопке
      setIsCopying(true);
      // Удаляем лишние поля
      delete values.copy_nodes;
      // Отправляем данные на сервер
      try {
        const data = await asyncCopyOntology(ontology.id, values);
        if (data.ontology) {
          // Добавляем новую онтологию
          dispatch(addOntologies(data.ontology));
          if (data.views.length > 0) {
            // Если есть виды, добавляем их
            dispatch(addView(data.views));
          }
          // Сбрасываем значения формы
          form.resetFields();
          // Скрываем модальку
          onCompleted();
        }
      } catch (error) {
        // Выводим ошибку
        message.error("Произошла ошибка копирования");
      } finally {
        // Отключаем лоадер
        setIsCopying(false);
      }
    },
    [dispatch, form, onCompleted, ontology.id]
  );

  const renderItem: ListRowRenderer = ({ index, key, style }) => {
    const item = checkboxes[index];
    return (
      <List.Item key={key} style={style}>
        <Checkbox value={item.id}>{item.label}</Checkbox>
      </List.Item>
    );
  };

  const handleChangeForm = (_: any, values: any) => {
    console.log("values :>> ", values);
    setIsVisibleViewsList(values.copy_views);
    setIsVisibleNodesList(values.copy_nodes);
  };

  return (
    <Form form={form} onFinish={handleFinish} layout="vertical" onValuesChange={handleChangeForm}>
      <Alert
        message="Если не выбран ни один вид, или ни одного узла, будут скопированы ВСЕ виды и узлы."
        type="info"
        showIcon
      />
      <Form.Item
        name="name"
        label="Имя новой онтологии"
        rules={[{ required: true, message: "Напишите название новой онтологии" }]}
      >
        <Input placeholder="Название онтологии" />
      </Form.Item>
      <Form.Item name="copy_views" label="Копировать только определенные виды онтологии?">
        <Switch checked={isVisibleViewsList} />
      </Form.Item>
      {isVisibleViewsList && (
        <Form.Item name="views" label="Выберите виды для копирования">
          <Select
            mode="multiple"
            allowClear
            options={views
              .filter((v) => v.model_id === ontology.id)
              .map((view) => {
                return { label: view.name, value: view.id };
              })}
            style={{ width: "100%" }}
            placeholder="Выберите виды для копирования"
          />
        </Form.Item>
      )}
      <Form.Item name="copy_nodes" label="Копировать только определенные узлы онтологии?">
        <Switch checked={isVisibleNodesList} />
      </Form.Item>
      {isVisibleNodesList && (
        <Form.Item name="nodes" label="Выберите узлы для копирования">
          {checkboxes.length > 0 && (
            <Checkbox.Group>
              <VList
                height={400}
                rowHeight={30}
                rowCount={checkboxes.length}
                rowRenderer={renderItem}
                width={550}
              />
            </Checkbox.Group>
          )}
        </Form.Item>
      )}
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isCopying}>
          Создать копию
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OntologyDuplicate;
