import React from "react";
import { ConfigProps } from "./ConfigProps";
import SelectControl from "./controls/SelectControl";
import SliderControl from "./controls/SliderControl";
import SwitchControl from "./controls/SwitchControl";

const LayoutConfig: React.FC<ConfigProps> = ({ options, setOptions }) => {
  const directions = [
    { value: "UD", label: "Сверху вниз" },
    { value: "DU", label: "Снизу вверх" },
    { value: "LR", label: "Слева направо" },
    { value: "RL", label: "Справа налево" },
  ];

  return (
    <>
      <SwitchControl
        label="Включить выкладку"
        value={options.layout?.hierarchical.enabled}
        onChange={(e) =>
          setOptions({
            ...options,
            layout: {
              ...options.layout,
              hierarchical: { ...options.layout.hierarchical, enabled: e },
            },
          })
        }
      />
      <SelectControl
        label="Метод сортировки"
        options={[
          { label: "hubsize", value: "hubsize" },
          { label: "directed", value: "directed" },
        ]}
        value={options.layout.hierarchical.sortMethod}
        onChange={(value) => {
          setOptions({
            ...options,
            layout: {
              ...options.layout,
              hierarchical: { ...options.layout.hierarchical, sortMethod: value },
            },
          });
        }}
      />
      <SelectControl
        label="Shake Towards"
        options={[
          { label: "roots", value: "roots" },
          { label: "leaves", value: "leaves" },
        ]}
        value={options.layout.hierarchical.shakeTowards}
        onChange={(value) => {
          setOptions({
            ...options,
            layout: {
              ...options.layout,
              hierarchical: { ...options.layout.hierarchical, shakeTowards: value },
            },
          });
        }}
      />

      <SelectControl
        label="Направление выкладки"
        options={directions}
        value={options.layout.hierarchical.direction}
        onChange={(value) => {
          setOptions({
            ...options,
            layout: {
              ...options.layout,
              hierarchical: { ...options.layout.hierarchical, direction: value },
            },
          });
        }}
      />

      <SliderControl
        label="Расстояние между элементами"
        value={options.layout?.hierarchical.nodeSpacing}
        onChange={(e: number) =>
          setOptions({
            ...options,
            layout: {
              ...options.layout,
              hierarchical: { ...options.layout.hierarchical, nodeSpacing: e },
            },
          })
        }
        min={10}
        max={500}
        step={10}
      />

      <SliderControl
        label="Расстояние между уровнями"
        value={options.layout?.hierarchical.levelSeparation}
        onChange={(e: number) =>
          setOptions({
            ...options,
            layout: {
              ...options.layout,
              hierarchical: { ...options.layout.hierarchical, levelSeparation: e },
            },
          })
        }
        min={10}
        max={500}
        step={10}
      />

      <SliderControl
        label="Расстояние между деревьями"
        value={options.layout?.hierarchical.treeSpacing}
        onChange={(e: number) =>
          setOptions({
            ...options,
            layout: {
              ...options.layout,
              hierarchical: { ...options.layout.hierarchical, treeSpacing: e },
            },
          })
        }
        min={10}
        max={500}
        step={10}
      />
    </>
  );
};

export default React.memo(LayoutConfig);
