import React, { useEffect, useState } from "react";
import { asyncLoadChangesByViewId } from "../../services/changes.service";

type HistoryProps = {
  view_id: string;
};

const History: React.FC<HistoryProps> = ({ view_id }) => {
  const [, setChanges] = useState<any>([]);

  useEffect(() => {
    (async () => {
      const changes = asyncLoadChangesByViewId(view_id);
      changes && setChanges(changes);
      console.log("changes :>> ", changes);
    })();
  }, [view_id]);

  return <p>История изменений {view_id} пуста</p>;
};

export default History;
