import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Menu, Layout, Drawer, Button, Popconfirm, Avatar } from "antd";
import {
  PartitionOutlined,
  FunnelPlotOutlined,
  HomeOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { RootState } from "../store";
import { asyncLoadOntologies } from "../store/ontologies/actions";
import { setLogOut } from "../store/auth/actions";
import Filter from "./Filter";

const { Header: TopMenu } = Layout;
const { SubMenu } = Menu;

function Header() {
  const dispatch = useDispatch();
  const location = useLocation();
  const group_attrs = useSelector((state: RootState) => state.graphs.group_attrs);
  const { ontologies, views, auth } = useSelector((state: RootState) => state);
  const [isVisibleFilter, setIsVisibleFilter] = useState(false);

  /**
   * Получаем ID онтологии по view_id из url если есть
   */
  const ontoId = useMemo(() => {
    const matchViewId = location.pathname.match(/(view)\/(.*)/);
    if (matchViewId && matchViewId[2]) {
      return views.find((v) => v.id === matchViewId[2])?.model_id;
    }
  }, [location.pathname, views]);

  /**
   * Подгружаем асинхронно все данные: виды, пресеты, онтологии
   */
  useEffect(() => {
    auth.isLoggedIn && dispatch(asyncLoadOntologies());
  }, [dispatch, auth.isLoggedIn]);

  /**
   * Если меняется адрес, скрываем форму фильтрации
   */
  useEffect(() => {
    setIsVisibleFilter(false);
  }, [location.pathname]);

  const logout = () => {
    dispatch(setLogOut());
    localStorage.removeItem("sso_token_leader_id");
  };

  if (!auth.isLoggedIn) return <></>;

  return (
    <TopMenu>
      <Menu mode="horizontal" theme="dark">
        {group_attrs.length > 0 && (
          <Menu.Item
            key="filter"
            icon={<FunnelPlotOutlined style={{ color: "#fff", fontSize: "1rem" }} />}
            onClick={() => setIsVisibleFilter(!isVisibleFilter)}
          ></Menu.Item>
        )}
        <Menu.Item key="home" icon={<HomeOutlined />}>
          <Link to="/ontologies">Домой</Link>
        </Menu.Item>
        <SubMenu title="Онтологии" key="ontologies" icon={<PartitionOutlined />}>
          {ontologies.map((ontology) => (
            <SubMenu title={ontology.name} key={ontology.id}>
              {views
                .filter((view) => view.model_id === ontology.id)
                .map((view) => (
                  <Menu.Item key={view.id}>
                    <Link to={`/view/${view.id}`}>{view.name}</Link>
                  </Menu.Item>
                ))}
            </SubMenu>
          ))}
        </SubMenu>
        {ontoId && (
          <SubMenu title="Редактировать" icon={<SettingOutlined />}>
            <Menu.Item>
              <Link to={`/presets/${ontoId}`}>Пресеты</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to={`/views/${ontoId}`}>Виды</Link>
            </Menu.Item>
          </SubMenu>
        )}
        <Menu.Item style={{ float: "right" }}>
          <Avatar size={40} icon={<UserOutlined />} src={auth.photo_urls.Small} />
          <Popconfirm
            placement="bottomRight"
            title="Вы уверены, что хотите выйти из системы?"
            onConfirm={logout}
            okText="Да"
            cancelText="Нет"
          >
            <Button type="text" style={{ color: "#fff" }}>
              Выйти
            </Button>
          </Popconfirm>
        </Menu.Item>
      </Menu>
      <Drawer
        visible={isVisibleFilter}
        placement="left"
        width={300}
        onClose={() => setIsVisibleFilter(false)}
        mask={false}
      >
        <Filter group_attrs={group_attrs} />
      </Drawer>
    </TopMenu>
  );
}

export default Header;
