import { UserState } from "../auth/types";
import { ModelId } from "../ontologies/types";

export interface IEditor {
  id: string;
  onto_id: ModelId;
  user: UserState;
}

export const SET_EDITORS = "EDITORS/SET";
export const ADD_EDITORS = "EDITORS/ADD";
export const REMOVE_EDITORS = "EDITORS/REMOVE";
export const UPDATE_EDITORS = "EDITORS/UPDATE";

interface IEditorSetAction {
  type: typeof SET_EDITORS;
  payload: IEditor[];
}

interface IEditorUpdateAction {
  type: typeof UPDATE_EDITORS;
  payload: IEditor;
}

interface IEditorAddAction {
  type: typeof ADD_EDITORS;
  payload: IEditor[];
}

interface IEditorRemoveAction {
  type: typeof REMOVE_EDITORS;
  payload: string;
}

export type EditorsActionTypes =
  | IEditorSetAction
  | IEditorUpdateAction
  | IEditorAddAction
  | IEditorRemoveAction;
