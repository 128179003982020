import { SET_LOADING, AppActionTypes, SET_REDIRECT } from "./types";

export function setLoading(status: boolean): AppActionTypes {
  return {
    type: SET_LOADING,
    payload: status,
  };
}

export function redirect(link?: string): AppActionTypes {
  return {
    type: SET_REDIRECT,
    payload: link,
  };
}
