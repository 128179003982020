import { SET_VIEWS, UPDATE_VIEWS, ADD_VIEWS, REMOVE_VIEWS, IView, ViewsActionTypes } from "./types";

const initialState: IView[] = [];
export function viewsReducer(state = initialState, action: ViewsActionTypes): IView[] {
  switch (action.type) {
    case SET_VIEWS:
      return action.payload;
    case ADD_VIEWS:
      return [...state, action.payload];
    case REMOVE_VIEWS:
      return state.filter((item) => item.id !== action.payload);
    case UPDATE_VIEWS:
      return state.map((item) => (item.id === action.payload.id ? action.payload : item));
    default:
      return state;
  }
}
