import React, { useState } from "react";
import { Input } from "antd";
import { SketchPicker } from "react-color";
import { ControlProps } from "./ControlProps";

const ColorControl: React.FC<ControlProps> = ({ label, value, onChange }) => {
  const [isVisibleColorPicker, setIsVisibleColorPicker] = useState(false);
  return (
    <div className="filter__item">
      {label}
      <Input
        style={{ backgroundColor: value, width: "100%" }}
        value={value}
        onClick={() => setIsVisibleColorPicker(!isVisibleColorPicker)}
      />
      {isVisibleColorPicker && (
        <SketchPicker color={value} onChangeComplete={(color) => onChange(color.hex)} />
      )}
    </div>
  );
};

export default ColorControl;
