import { Button, PageHeader, Select, Space, Input, Drawer, Form, Collapse } from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Options } from "vis-network/standalone/esm/vis-network";

import { RootState } from "../store";
import { addPresets, removePresets, updatePresets } from "../store/presets/actions";
import { edges, nodes } from "../helpers/fakeData";
import { addPreset, removePreset, savePreset } from "../services/presets.service";
import PhysicsConfig from "../components/presets/PhysicsConfig";
import LayoutConfig from "../components/presets/LayoutConfig";
import NodesConfig from "../components/presets/NodesConfig";
import EdgesConfig from "../components/presets/EdgesConfig";
import VisNetwork from "../components/VisNetwork";

const { Panel } = Collapse;

const Presets = () => {
  const defaultOptions = useMemo<Options>(() => {
    return {
      height: "100%",
      locale: "ru",
      physics: {
        enabled: true,
        solver: "barnesHut",
        barnesHut: {
          avoidOverlap: 0.5,
          springLength: 100,
        },
        forceAtlas2Based: {
          avoidOverlap: 0.5,
          springLength: 100,
        },
        repulsion: {
          nodeDistance: 100,
        },
        hierarchicalRepulsion: {
          avoidOverlap: 0.5,
          nodeDistance: 100,
        },
      },
      nodes: {
        shape: "box",
        size: 10,
        shadow: false,
        opacity: 1,
        color: "#97C2FC",
        widthConstraint: {
          maximum: 300,
        },
      },
      edges: {
        arrows: "to",
        dashes: false,
        color: {
          color: "#848484",
          highlight: "#848484",
        },
        smooth: {
          enabled: true,
          type: "dynamic",
          roundness: 0.5,
        },
      },
      layout: {
        randomSeed: 1,
        improvedLayout: true,
        hierarchical: {
          enabled: true,
          direction: "LR",
          sortMethod: "directed",
          shakeTowards: "roots",
          nodeSpacing: 100,
          levelSeparation: 150,
          treeSpacing: 200,
        },
      },
    };
  }, []);

  const { onto_id } = useParams<{ onto_id: string }>();
  const history = useHistory();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const presets = useSelector((state: RootState) => state.presets);
  const [options, setOptions] = useState<Options>(defaultOptions);
  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);
  const [isVisibleConfig, setIsVisibleConfig] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState<string | undefined>();

  /**
   * Функция устанавливает настройки пресета на новые по ID
   * @param id ID пресета
   */
  const handleChangePreset = useCallback(
    (id: string) => {
      const preset = presets.find((preset) => preset.id === id);
      if (preset) {
        setOptions({ ...defaultOptions, ...preset.content });
        //setOptions(merge(defaultOptions, preset.content));
        setSelectedPreset(preset.id);
        setIsVisibleConfig(true);
      }
    },
    [defaultOptions, presets]
  );

  /**
   * Сохраняем новый пресет
   * @param values Параметры формы
   * @returns
   */
  const handleSubmit = useCallback(
    async (values: any) => {
      if (!onto_id) return;
      // Отправляем запрос на сервер
      const data = await addPreset(onto_id, values);
      if (data) {
        // Сбрасываем поля формы
        form.resetFields();
        // Добавляем данные в хранилище
        dispatch(addPresets(data));
        // Делаем текущий пресет активным
        setSelectedPreset(data.id);
        // Скрываем панель добавления пресета
        setIsVisibleDrawer(false);
        // Показываем панель редактирования пресета
        setIsVisibleConfig(true);
      }
    },
    [dispatch, form, onto_id]
  );

  /**
   * Удаление пресета
   * @returns
   */
  const handleRemovePreset = useCallback(async () => {
    if (!selectedPreset) return;
    // Получаем статус удаления с сервера
    const status = await removePreset(selectedPreset);
    if (status) {
      // Удаляем из хранилища
      dispatch(removePresets(selectedPreset));
      // Сбрасываем значение активного пресета
      setSelectedPreset(undefined);
    }
  }, [dispatch, selectedPreset]);

  /**
   * Сохраняем насройки текущего пресета
   * @returns
   */
  const handleSavePreset = useCallback(() => {
    if (!selectedPreset) return;
    // Получаем данные текущего пресета
    const preset = presets.find((preset) => preset.id === selectedPreset);
    if (preset) {
      // Обновляем данные в хранилище
      dispatch(updatePresets({ ...preset, content: options }));
      // Отправляем новые данные на сервер
      savePreset(selectedPreset, options);
    }
  }, [dispatch, options, presets, selectedPreset]);

  return (
    <>
      <PageHeader
        onBack={() => history.goBack()}
        title="Пресеты"
        subTitle="Настройки для отображения онтологий"
        style={{ paddingLeft: isVisibleConfig ? 360 : 0 }}
        extra={[
          <Select
            style={{ width: 200 }}
            key="select"
            placeholder="Выберите пресет"
            value={selectedPreset}
            onChange={handleChangePreset}
            options={presets
              .filter((p) => p.onto_id === onto_id)
              .map((preset) => {
                return {
                  key: preset.id,
                  label: preset.name,
                  value: preset.id,
                };
              })}
          />,
          <Button key="add" type="primary" onClick={() => setIsVisibleDrawer(true)}>
            Добавить
          </Button>,
        ]}
      />
      <VisNetwork data={{ edges, nodes }} options={options} />
      <Drawer
        mask={false}
        width={350}
        title="Настроить пресет"
        placement="left"
        onClose={() => setIsVisibleConfig(false)}
        visible={isVisibleConfig}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <Space>
            <Button type="primary" onClick={handleSavePreset}>
              Сохранить изменения
            </Button>
            <Button type="primary" danger onClick={handleRemovePreset}>
              Удалить пресет
            </Button>
          </Space>
        }
      >
        <Collapse defaultActiveKey={["general"]} ghost accordion>
          <Panel header="Общие настройки" key="general">
            <PhysicsConfig options={options} setOptions={setOptions} />
          </Panel>
          <Panel header="Выкладка" key="layout">
            <LayoutConfig options={options} setOptions={setOptions} />
          </Panel>
          <Panel header="Настройки узлов" key="nodes">
            <NodesConfig options={options} setOptions={setOptions} />
          </Panel>
          <Panel header="Настройки связей" key="edges">
            <EdgesConfig options={options} setOptions={setOptions} />
          </Panel>
          {process.env.NODE_ENV === "development" && (
            <Panel header="JSON" key="json">
              <pre>{JSON.stringify(options, null, 2)}</pre>
            </Panel>
          )}
        </Collapse>
      </Drawer>
      <Drawer
        width={350}
        title="Добавить новый пресет"
        placement="right"
        onClose={() => setIsVisibleDrawer(false)}
        visible={isVisibleDrawer}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item
            label="Название"
            name="name"
            rules={[{ required: true, message: "Укажите название пресета" }]}
          >
            <Input placeholder="Название пресета" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Добавить пресет
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default Presets;
