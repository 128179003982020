import React from "react";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { INodeClass } from "../store/nodeclasses/types";
import { IQueryParams } from "../hooks/useQueryParams";

type VisibleStatusClassProps = {
  node: INodeClass;
  queryParams: IQueryParams;
  onToggle: (id: string) => void;
};

const VisibleStatusClass: React.FC<VisibleStatusClassProps> = ({ node, queryParams, onToggle }) => {
  const isExist = queryParams.hide?.includes(node.id);
  return (
    <Button
      data-testid="button"
      type={!isExist ? "default" : "dashed"}
      key={node.id}
      onClick={() => onToggle(node.id)}
      icon={!isExist ? <EyeOutlined /> : <EyeInvisibleOutlined />}
    >
      {node.name}
    </Button>
  );
};

export default VisibleStatusClass;
