import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";

import { RootState } from "../store";

interface IProtectedRouteProps extends RouteProps {
  allowed?: string[];
}

const ProtectedRoute: React.FC<IProtectedRouteProps> = ({
  component: Component,
  allowed,
  ...rest
}) => {
  const { isLoggedIn, role } = useSelector((state: RootState) => state.auth);

  // Проверяем авторизацию
  if (process.env.NODE_ENV === "production" && !isLoggedIn) return <Redirect to="/" />;

  // Проверяем роль юзера
  if (!allowed?.includes(role)) return <Redirect to="/ontologies" />;

  return <Route {...rest} component={Component} />;
};

export default ProtectedRoute;
