import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { message, Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { DataSet, IdType, Network, Options } from "vis-network/standalone/esm/vis-network";
import { useParams } from "react-router-dom";

import { EventType } from "../types/EventType";
import { RootState } from "../store";
import { IEdge, IGroupAttrs, INode } from "../store/graphs/types";
import { IView } from "../store/views/types";
import { asyncLoadGraphs, updateGraphs } from "../store/graphs/actions";
import { asyncLoadViewById } from "../services/views.service";
import { asyncLoadPresetById } from "../services/presets.service";
import { asyncLoadGroupAttrs } from "../services/group_attrs.service";

import VisNetwork from "../components/VisNetwork";
import SharedNodePanel from "../components/shared/SharedNodePanel";
import SharedEdgePanel from "../components/shared/SharedEdgePanel";
import CommentsManager from "../components/comments/CommentsManager";
import Legend from "../components/legend/Legend";

const SharedOntology = () => {
  const { view_id } = useParams<{ view_id: string }>();
  const dispatch = useDispatch();
  const refNetwork = useRef<Network | null>();
  const refNodes = useRef<DataSet | null>();
  const { nodes, edges } = useSelector((state: RootState) => state.graphs);
  const { comments, presets } = useSelector((state: RootState) => state);
  const [selectedStartedNode, setSelectedStartedNode] = useState<IdType[]>([]);
  const [view, setView] = useState<IView>();
  const [options, setOptions] = useState<Options>();
  const [groupAttrs, setGroupAttrs] = useState<IGroupAttrs[]>([]);
  const [isGroupAttrsLoaded, setIsGroupAttrsLoaded] = useState(false);
  const [tempDataNode, setTempDataNode] = useState<INode>();
  const [tempDataEdge, setTempDataEdge] = useState<IEdge>();

  /**
   * Получаем рефы visnetwork'a
   */
  const getNetwork = useCallback((network: Network) => {
    refNetwork.current = network;
  }, []);

  const getNodes = useCallback((nodes: DataSet) => {
    refNodes.current = nodes;
  }, []);

  /**
   * Фокусируемся на указанной ноде
   */
  const handleFocus = useCallback((node_id: IdType) => {
    refNetwork.current?.focus(node_id);
  }, []);

  /**
   * Делаем ноду выбранной
   */
  const handleSelectNode = useCallback((node_id: IdType) => {
    refNetwork.current?.selectNodes([node_id]);
  }, []);

  /**
   * Делаем ноду выбранной
   */
  const handleSelectEdge = useCallback((edge_id: IdType) => {
    refNetwork.current?.selectNodes([edge_id]);
  }, []);

  /**
   * Загружаем данные вида: параметры, сценарии, пресет
   */
  const getView = useCallback(async () => {
    // Получаем данные вида с сервера
    const view = await asyncLoadViewById(view_id);
    if (view) {
      // Записываем данные вида в стейт
      setView(view);
      // Получаем пресет для вида
      const preset = await asyncLoadPresetById(view.preset_id);
      preset && setOptions(preset.content);
      // Получаем список групповых атрибутов
      const group_attrs = await asyncLoadGroupAttrs(view.model_id);
      if (group_attrs) {
        setGroupAttrs(group_attrs);
        setIsGroupAttrsLoaded(true);
      }
    } else {
      message.error("Проблема с загрузкой параметров публикации");
    }
  }, [view_id]);

  // Запускаем загрузку данных при монтировании
  useEffect(() => {
    getView();
  }, [getView]);

  /**
   * Формируем данные исходя из настроек
   */
  const data = useMemo(() => {
    // Если включен пошаговый режим
    if (view?.is_hidden_nodes_on_start) {
      // Проверяем есть ли выбранная стартовая нода
      if (selectedStartedNode.length > 0) {
        // Ищем все связи подряд
        const filteredAllEdges = edges.filter(
          (edge) =>
            selectedStartedNode.includes(edge.from!) || selectedStartedNode.includes(edge.to!)
        );
        // Ищем все ноды относящиеся к связям
        const filteredNodes = nodes.filter(
          (node) =>
            filteredAllEdges.some((e) => e.from === node.id) ||
            filteredAllEdges.some((e) => e.to === node.id)
        );
        // Возвращаем отфильтрованные ноды и связи
        return {
          nodes: filteredNodes,
          edges: filteredAllEdges,
        };
      } else {
        // Возвращаем пустой граф
        return { nodes: [], edges: [] };
      }
    } else {
      return { nodes, edges };
    }
  }, [edges, nodes, selectedStartedNode, view?.is_hidden_nodes_on_start]);

  /**
   * Подгружаем ассинхронно данные онтологии
   */
  useEffect(() => {
    // Загружаем узлы и связи по групповым атрибутам
    isGroupAttrsLoaded && dispatch(asyncLoadGraphs(view_id, groupAttrs));
    // При размонтировании компонента убираем все
    return () => {
      // убираем панель информации о выбранной ноде
      setTempDataNode(undefined);
      // удаляем информацию о нодах и связях
      dispatch(updateGraphs([], []));
    };
  }, [dispatch, view_id, groupAttrs, isGroupAttrsLoaded]);

  /**
   * Задаем события для VisNetwork
   */
  const events: EventType = useMemo(() => {
    return {
      select: (params: any) => {
        if (params.nodes.length > 0) {
          // Получаем его идентификатор
          const nodeId = params.nodes[0];
          // Ищем его в массиве
          const node = nodes.find((n) => n.id === nodeId);
          // Записываем во временные данные
          setTempDataNode(node);
        } else {
          // Получаем его идентификатор
          const edgeId = params.edges[0];
          // Ищем его в массиве
          const edge = edges.find((n) => n.id === edgeId);
          // Записываем во временные данные
          setTempDataEdge(edge);
        }
      },
      doubleClick: (params: any) => {
        const id = params.nodes[0];
        if (!selectedStartedNode.includes(id)) {
          setSelectedStartedNode([...selectedStartedNode, id]);
        }
      },
    };
  }, [edges, nodes, selectedStartedNode]);

  /**
   * Активируем и центрируем узел или связь выбранную в комментариях
   */
  const handleSelectComment = useCallback(
    (model_item_id: string) => {
      // получаем узел комментария
      const findNodeByModelItemId = nodes.find((node) => node.model_node_id === model_item_id);
      // получаем связь комментариях
      const findEdgeByModelItemId = edges.find((edge) => edge.model_edge_id === model_item_id);
      // Если есть узел, синтезируем со списком старовых узлов
      if (findNodeByModelItemId?.id) {
        handleSelectNode(findNodeByModelItemId.id);
        handleFocus(findNodeByModelItemId.id);
      }
      // Если есть связь, фокусируемся на ней
      if (findEdgeByModelItemId?.id) {
        handleSelectEdge(findEdgeByModelItemId.id);
      }
    },
    [nodes, edges, handleSelectNode, handleFocus, handleSelectEdge]
  );

  return (
    <>
      {view?.is_hidden_nodes_on_start && (
        <div className="ontology-filter">
          <Select
            mode="multiple"
            showSearch
            autoFocus
            filterOption
            optionFilterProp="label"
            style={{ width: "100%" }}
            placeholder="Выберите стартовый узел"
            value={selectedStartedNode}
            options={nodes.map((node) => {
              return { label: node.label!, value: node.id! as string };
            })}
            onChange={(value) => setSelectedStartedNode(value)}
          />
        </div>
      )}
      {groupAttrs.length > 0 && <Legend group_attrs={groupAttrs} presets={presets} />}
      {options && (
        <VisNetwork
          events={events}
          data={data}
          options={options}
          getNetwork={getNetwork}
          getNodes={getNodes}
        />
      )}
      {view && (
        <CommentsManager
          onto_id={view.model_id}
          comments={comments}
          onSelect={handleSelectComment}
        />
      )}
      {tempDataEdge && (
        <SharedEdgePanel
          onFocus={handleFocus}
          tempDataEdge={tempDataEdge}
          setTempDataEdge={setTempDataEdge}
          onSelectComment={handleSelectComment}
        />
      )}
      {tempDataNode && (
        <SharedNodePanel
          tempDataNode={tempDataNode}
          onSelectComment={handleSelectComment}
          onFocus={handleFocus}
          setTempDataNode={setTempDataNode}
        />
      )}
    </>
  );
};

export default SharedOntology;
