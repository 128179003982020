import { ModelId } from "../ontologies/types";
import { PresetId } from "../presets/types"

export type ViewId = string & { readonly __tag: unique symbol };
export interface IView {
  id: ViewId;
  name: string;
  model_id: ModelId;
  preset_id: PresetId;
  link: string | null;
  is_editable: boolean;
  is_hidden_nodes_on_start: boolean;
  is_publication: boolean;
  description?: string | null;
}

export const SET_VIEWS = "VIEWS/SET";
export const ADD_VIEWS = "VIEWS/ADD";
export const REMOVE_VIEWS = "VIEWS/REMOVE";
export const UPDATE_VIEWS = "VIEWS/UPDATE";

interface IViewetAction {
  type: typeof SET_VIEWS;
  payload: IView[];
}

interface IViewUpdateAction {
  type: typeof UPDATE_VIEWS;
  payload: IView;
}

interface IViewAddAction {
  type: typeof ADD_VIEWS;
  payload: IView;
}

interface IViewRemoveAction {
  type: typeof REMOVE_VIEWS;
  payload: string;
}

export type ViewsActionTypes =
  | IViewetAction
  | IViewUpdateAction
  | IViewAddAction
  | IViewRemoveAction;
