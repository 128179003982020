import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { whoami } from "../services/auth.service";
import { setLogIn } from "../store/auth/actions";

/**
 * Хук проверки авторизации
 */
export const useAuth = () => {
  const dispatch = useDispatch();
  // Состояние проверки, по умолчанию включено
  const [isChecking, setIsChecking] = useState(true);
  // Асинхронная функция проверки
  const verifyAuth = useCallback(async () => {
    // Получаем токен
    const token = localStorage.getItem("sso_token_leader_id");
    if (token) {
      // Если токен есть, получаем данные для юзера
      const auth = await whoami(token);
      // Если данные получены, записываем в стейт
      if (auth) {
        dispatch(setLogIn({ ...auth, token, isLoggedIn: true }));
      }
    }
    // В любом случае отключаем проверку
    setIsChecking(false);
  }, [dispatch]);

  useEffect(() => {
    verifyAuth();
  }, [verifyAuth]);

  return {
    isChecking,
  };
};
