import React from "react";
import { IGroupAttrsValue } from "../../store/graphs/types";
import { IPresets } from "../../store/presets/types";

type LegendGroupItemProps = {
  attr: IGroupAttrsValue;
  preset?: IPresets;
};

const LegendGroupItem: React.FC<LegendGroupItemProps> = ({ attr, preset }) => {
  const color = preset?.content.nodes?.color;
  return (
    <div
      className="legend__group-item"
      style={{ backgroundColor: color ? (color as string) : "transparent" }}
    >
      {attr.value}
    </div>
  );
};

export default LegendGroupItem;
