import axios from "axios";
import { IEditor } from "../store/editors/types";

export const asyncLoadEditors = async (): Promise<IEditor[] | undefined> => {
  try {
    const res = await axios(`/editors`);
    return res.data;
  } catch (error) {}
};

export const asyncCreateEditors = async (user: any): Promise<IEditor[] | undefined> => {
  try {
    const res = await axios.post("/editors", user);
    return res.data;
  } catch (error) {}
};

export const asyncUpdateEditors = async (user: IEditor): Promise<IEditor | undefined> => {
  try {
    const res = await axios.put(user.id, user);
    return res.data;
  } catch (error) {}
};

export const asyncRemoveEditors = async (id: string): Promise<boolean | undefined> => {
  try {
    const res = await axios.delete(`/editors/${id}`);
    return res.status === 204;
  } catch (error) {}
};
