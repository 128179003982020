import { message } from "antd";
import axios from "axios";

export const asyncDeleteOntology = async (id: string) => {
  try {
    const res = await axios.delete(`/ontologies/${id}`);
    return res.status === 204;
  } catch (error) {
    return false;
  }
};

export const asyncCopyOntology = async (id: string, values: any) => {
  try {
    const res = await axios.post(`/ontologies/${id}/copies`, values);
    return res.data;
  } catch (error) {
    message.error("Произошла ошибка копирования");
    return undefined;
  }
};
