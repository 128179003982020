import {
  ADD_SCENARIOS,
  REMOVE_SCENARIOS,
  IScenario,
  ScenarioActionTypes,
  SET_SCENARIOS,
  UPDATE_SCENARIOS,
} from "./types";

const initialState: IScenario[] = [];
export function scenariosReducer(state = initialState, action: ScenarioActionTypes): IScenario[] {
  switch (action.type) {
    case SET_SCENARIOS:
      return action.payload;
    case ADD_SCENARIOS:
      return [...state, action.payload];
    case REMOVE_SCENARIOS:
      return state.filter((preset) => preset.id !== action.payload);
    case UPDATE_SCENARIOS:
      return state.map((item) => (item.id === action.payload.id ? action.payload : item));
    default:
      return state;
  }
}
