import axios from "axios";
import { IGroupAttrs } from "../store/graphs/types";
import { INodeClass } from "../store/nodeclasses/types";
import { ModelId } from "../store/ontologies/types";

export const saveGroupAttrs = async (id: string, key: string, value: any) => {
  try {
    const res = await axios.put(`/group_attrs/${id}`, { [key]: value });
    return res.data;
  } catch (error) {}
};

export const asyncLoadGroupAttrs = async (model_id: ModelId): Promise<IGroupAttrs[] | undefined> => {
  try {
    const res = await axios(`/ontologies/${model_id}/group_attrs`);
    return res.data;
  } catch (error) {}
};

export const asyncLoadNodeClasses = async (): Promise<INodeClass[] | undefined> => {
  try {
    const res = await axios(`/nodeclasses`);
    return res.data;
  } catch (error) {}
};
