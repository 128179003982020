import {
  ADD_NODECLASSES,
  REMOVE_NODECLASSES,
  INodeClass,
  NodeClassActionTypes,
  SET_NODECLASSES,
  UPDATE_NODECLASSES,
} from "./types";

const initialState: INodeClass[] = [];
export function nodeclassesReducer(
  state = initialState,
  action: NodeClassActionTypes
): INodeClass[] {
  switch (action.type) {
    case SET_NODECLASSES:
      return action.payload;
    case ADD_NODECLASSES:
      return [...state, action.payload];
    case REMOVE_NODECLASSES:
      return state.filter((preset) => preset.id !== action.payload);
    case UPDATE_NODECLASSES:
      return state.map((item) => (item.id === action.payload.id ? action.payload : item));
    default:
      return state;
  }
}
