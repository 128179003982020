import React, { useEffect, useState } from "react";
import { Avatar, Button, Col, Drawer, Form, Input, PageHeader, Row, Select, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";

import { RootState } from "../store";
import { asyncCreateEditors, asyncRemoveEditors } from "../services/editors.service";
import { UserState } from "../store/auth/types";
import { addEditors, removeEditors } from "../store/editors/actions";
import { IEditor } from "../store/editors/types";
import { UserOutlined } from "@ant-design/icons";

const { Column } = Table;

const Editors = () => {
  const { onto_id } = useParams<{ onto_id: string }>();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const editors = useSelector((state: RootState) => state.editors);
  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);
  const [users, setUsers] = useState<UserState[]>([]);

  /**
   * Получаем список пользователей
   * Поддерживаем его актуальным при каждом монтировании компонена,
   * чтобы при добавлнии нового юзера сразу его видеть
   */
  useEffect(() => {
    (async () => {
      const users = await axios(`/users`);
      users && setUsers(users.data);
    })();
  }, [onto_id]);

  /**
   * Добавляем нового редактора в онтологию
   * @param values значения формы
   */
  const handleAdd = async (values: any) => {
    try {
      // Отправляем запрос на сервер
      const editors = await asyncCreateEditors(values);
      // Добавляем в хранилище
      editors && dispatch(addEditors(editors));
      // Скрываем панель
      setIsVisibleDrawer(false);
      // Сбрасываем значения формы
      form.resetFields();
    } catch (error) {}
  };

  /**
   * Удаляем редактора из онтологии
   * @param id ID редактора
   */
  const handleRemove = async (id: string) => {
    const isRemoved = asyncRemoveEditors(id);
    isRemoved && dispatch(removeEditors(id));
  };

  const filteredEditors = editors.filter((editor) => editor.onto_id === onto_id);

  return (
    <>
      <PageHeader
        onBack={() => history.goBack()}
        title="Редакторы"
        subTitle="Назначить доступ пользователям к онтологиям"
        extra={[
          <Button key="add" type="primary" onClick={() => setIsVisibleDrawer(true)}>
            Добавить
          </Button>,
        ]}
      />
      <Row gutter={[48, 48]}>
        <Col span={24}>
          <Table rowKey="id" dataSource={filteredEditors}>
            <Column
              width={64}
              title={null}
              render={(_, record: IEditor) => (
                <Avatar size={40} icon={<UserOutlined />} src={record.user.photo_urls.Small} />
              )}
            />
            <Column
              title="Leader ID"
              render={(_, record: IEditor) => <span>{record.user.leader_id}</span>}
            />
            <Column
              title="Имя"
              render={(_, record: IEditor) => <span>{record.user.firstname}</span>}
            />
            <Column
              title="Фамилия"
              render={(_, record: IEditor) => <span>{record.user.lastname}</span>}
            />
            <Column
              title="E-mail"
              render={(_, record: IEditor) => <span>{record.user.email}</span>}
            />
            <Column
              title={<p style={{ textAlign: "right" }}>Действие</p>}
              key="action"
              render={(_, record: IEditor) => (
                <Button
                  style={{ float: "right" }}
                  type="primary"
                  danger
                  onClick={() => handleRemove(record.id)}
                >
                  Удалить
                </Button>
              )}
            />
          </Table>
        </Col>
      </Row>
      <Drawer
        width={350}
        title="Добавить нового редактора"
        placement="right"
        onClose={() => setIsVisibleDrawer(false)}
        visible={isVisibleDrawer}
      >
        <Form form={form} onFinish={handleAdd} initialValues={{ onto_id }} layout="vertical">
          <Form.Item hidden={true} name="onto_id">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            label="Leader ID"
            name="users"
            rules={[{ required: true, message: "Выберите пользователей" }]}
          >
            <Select
              mode="multiple"
              optionFilterProp="label"
              options={users
                .filter((user) => !filteredEditors.find((editor) => user.id === editor.user.id))
                .map((user) => {
                  return {
                    label: `${user.leader_id}: ${user.firstname} ${user.lastname}`,
                    value: user.id,
                  };
                })}
              showSearch
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Добавить
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default Editors;
