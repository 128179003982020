export type ModelId = string & { readonly __tag: unique symbol };
export interface IOntology {
  id: ModelId;
  name: string;
}

export const SET_ONTOLOGIES = "ONTOLOGIES/SET";
export const ADD_ONTOLOGIES = "ONTOLOGIES/ADD";
export const REMOVE_ONTOLOGIES = "ONTOLOGIES/REMOVE";

interface IOntologiesSetAction {
  type: typeof SET_ONTOLOGIES;
  payload: IOntology[];
}

interface IOntologiesAddAction {
  type: typeof ADD_ONTOLOGIES;
  payload: IOntology;
}

interface IOntologiesRemoveAction {
  type: typeof REMOVE_ONTOLOGIES;
  payload: string;
}

export type OntologiesActionTypes =
  | IOntologiesSetAction
  | IOntologiesAddAction
  | IOntologiesRemoveAction;
