import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout, Spin } from "antd";

import { RootState } from "./store";
import { useAuth } from "./hooks/useAuth";

import Ontology from "./pages/Ontology";
import Presets from "./pages/Presets";
import Views from "./pages/Views";
import Attributes from "./pages/Attributes";
import Ontologies from "./pages/Ontologies";
import SSORedirect from "./pages/SSORedirect";
import NoFound from "./pages/NoFound";
import Loader from "./components/Loader";
import Header from "./components/Header";
import Scenario from "./pages/Scenario";
import Demo from "./pages/Demo";
import Login from "./pages/Login";
import Publications from "./pages/Publications";
import SharedOntology from "./pages/SharedOntology";
import SharedScenario from "./pages/SharedScenario";
import Editors from "./pages/Editors";
import ProtectedRoute from "./components/ProtectedRoute";
import Discussions from "./pages/Discussions";
import Compare from "./pages/Compare";

const { Content } = Layout;

function App() {
  const { isLoading } = useSelector((state: RootState) => state.app);
  const { isChecking } = useAuth();
  return (
    <Router>
      <Layout>
        <Header />
        {!isChecking ? (
          <Content>
            <Spin tip="Загрузка..." spinning={isLoading}>
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/demo" component={Demo} />
                <Route exact path="/sso_redirect" component={SSORedirect} />
                <ProtectedRoute
                  exact
                  path="/ontologies"
                  allowed={["admin", "user"]}
                  component={Ontologies}
                />
                <ProtectedRoute
                  exact
                  path="/view/:view_id"
                  allowed={["admin", "user"]}
                  component={Ontology}
                />
                <ProtectedRoute
                  exact
                  path="/scenario/:view_id"
                  allowed={["admin", "user"]}
                  component={Scenario}
                />
                <ProtectedRoute
                  exact
                  path="/presets/:onto_id"
                  allowed={["admin", "user"]}
                  component={Presets}
                />
                <ProtectedRoute
                  exact
                  path="/views/:onto_id"
                  allowed={["admin", "user"]}
                  component={Views}
                />
                <ProtectedRoute
                  exact
                  path="/editors/:onto_id"
                  allowed={["admin"]}
                  component={Editors}
                />
                <ProtectedRoute
                  exact
                  path="/discussions/:onto_id"
                  allowed={["admin"]}
                  component={Discussions}
                />
                <ProtectedRoute
                  exact
                  path="/compare/:onto_id"
                  allowed={["admin"]}
                  component={Compare}
                />
                <ProtectedRoute
                  exact
                  path="/views/:view_id/publications"
                  allowed={["admin", "user"]}
                  component={Publications}
                />
                <ProtectedRoute
                  exact
                  path="/attributes/:view_id"
                  allowed={["admin", "user"]}
                  component={Attributes}
                />
                <Route exact path="/shared/:view_id/ontology" component={SharedOntology} />
                <Route exact path="/shared/:view_id/scenario" component={SharedScenario} />
                <Route path="*" component={NoFound} />
              </Switch>
            </Spin>
          </Content>
        ) : (
          <Loader />
        )}
      </Layout>
    </Router>
  );
}

export default App;
