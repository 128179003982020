import React from "react";
import { ConfigProps } from "./ConfigProps";
import SelectControl from "./controls/SelectControl";
import SliderControl from "./controls/SliderControl";
import SwitchControl from "./controls/SwitchControl";

const PhysicsConfig: React.FC<ConfigProps> = ({ options, setOptions }) => {
  const solverItems = [
    { label: "barnesHut", value: "barnesHut" },
    { label: "repulsion", value: "repulsion" },
    { label: "hierarchicalRepulsion", value: "hierarchicalRepulsion" },
    { label: "forceAtlas2Based", value: "forceAtlas2Based" },
  ];
  return (
    <>
      <SwitchControl
        label="Физика"
        value={options.physics?.enabled}
        onChange={(enabled) => setOptions({ ...options, physics: { ...options.physics, enabled } })}
      />
      <SelectControl
        label="Физический движок"
        options={solverItems}
        value={options.physics?.solver}
        onChange={(solver) => setOptions({ ...options, physics: { ...options.physics, solver } })}
      />
      {!["repulsion"].includes(options.physics?.solver) && (
        <SliderControl
          label="Уровень избегания перекрытия"
          onChange={(e: number) => {
            let newOption = Object.assign({}, options);
            newOption.physics[options.physics?.solver].avoidOverlap = e;
            setOptions(newOption);
          }}
          value={options.physics[options.physics?.solver].avoidOverlap}
          step={0.1}
          min={0}
          max={1}
        />
      )}
      {["repulsion", "hierarchicalRepulsion"].includes(options.physics?.solver) && (
        <SliderControl
          label="Расстояние узлов для отталкивания"
          onChange={(e: number) => {
            let newOption = Object.assign({}, options);
            newOption.physics[options.physics?.solver].nodeDistance = e;
            setOptions(newOption);
          }}
          value={options.physics[options.physics?.solver].nodeDistance}
          step={10}
          min={0}
          max={500}
        />
      )}
      {["barnesHut", "forceAtlas2Based"].includes(options.physics?.solver) && (
        <SliderControl
          label="Длинна связей"
          onChange={(e: number) => {
            let newOption = Object.assign({}, options);
            newOption.physics[options.physics?.solver].springLength = e;
            setOptions(newOption);
          }}
          value={options.physics[options.physics?.solver].springLength}
          step={10}
          min={0}
          max={500}
        />
      )}
    </>
  );
};

export default React.memo(PhysicsConfig);
