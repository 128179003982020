import axios from "axios";
import { AppThunk } from "..";
import { filtersQueryString } from "../../helpers/filters.helpers";
import { asyncLoadGroupAttrs } from "../../services/group_attrs.service";
import { ModelId } from "../ontologies/types";

import { setLoading } from "../app/actions";
import {
  ADD_EDGE,
  ADD_NODE,
  GraphsActionTypes,
  IEdge,
  IGraphs,
  IGroupAttrs,
  INode,
  REMOVE_EDGE,
  REMOVE_NODE,
  SET_GRAPHS,
  SET_GRAPHS_NODE_POSITION,
  SET_GROUP_ATTRS,
  SET_GROUP_ATTRS_PRIORITY,
  SET_GROUP_ATTRS_VALUE,
  UPDATE_EDGE,
  UPDATE_GRAPHS,
  UPDATE_GROUP_ATTRS,
  UPDATE_NODE,
} from "./types";

export function setGraphs(graphs: IGraphs): GraphsActionTypes {
  return {
    type: SET_GRAPHS,
    payload: graphs,
  };
}

export function updateGraphs(nodes: INode[], edges: IEdge[]): GraphsActionTypes {
  return {
    type: UPDATE_GRAPHS,
    payload: { nodes, edges },
  };
}

export function setNodePosition(id: string, x: number, y: number): GraphsActionTypes {
  return {
    type: SET_GRAPHS_NODE_POSITION,
    payload: { id, x, y },
  };
}

export function addNode(node: INode): GraphsActionTypes {
  return {
    type: ADD_NODE,
    payload: node,
  };
}

export function updateNode(node: INode): GraphsActionTypes {
  return {
    type: UPDATE_NODE,
    payload: node,
  };
}

export function removeNode(id: string): GraphsActionTypes {
  return {
    type: REMOVE_NODE,
    payload: id,
  };
}

export function addEdge(edge: IEdge): GraphsActionTypes {
  return {
    type: ADD_EDGE,
    payload: edge,
  };
}

export function updateEdge(edge: IEdge): GraphsActionTypes {
  return {
    type: UPDATE_EDGE,
    payload: edge,
  };
}

export function removeEdge(id: string): GraphsActionTypes {
  return {
    type: REMOVE_EDGE,
    payload: id,
  };
}

export function setGroupAttrs(group_attrs: IGroupAttrs[]): GraphsActionTypes {
  return {
    type: SET_GROUP_ATTRS,
    payload: group_attrs,
  };
}

export function setGroupAttrValue(name: string, value: string): GraphsActionTypes {
  return {
    type: SET_GROUP_ATTRS_VALUE,
    payload: { name, value },
  };
}

export function updateGroupAttr(id: string, key: string, value: any): GraphsActionTypes {
  return {
    type: UPDATE_GROUP_ATTRS,
    payload: { id, key, value },
  };
}

export function setGroupAttrsPriority(id: string, priority: number): GraphsActionTypes {
  return {
    type: SET_GROUP_ATTRS_PRIORITY,
    payload: { id, priority },
  };
}

export const asyncLoadGraphs = (view_id: string, group_attrs: IGroupAttrs[]): AppThunk => async (
  dispatch
) => {
  try {
    // Если же данных нет или они отличаются
    dispatch(setLoading(true));
    // Формируем строку параметров
    const params = filtersQueryString(group_attrs);
    // Отправляем запрос на сервер
    const req = await axios(`/views/${view_id}/nodes${params}`);
    // Если все ок
    if (req.status === 200) {
      const { nodes, edges } = req.data;
      // Обновляем ноды и связи
      dispatch(updateGraphs(nodes, edges));
      return req.data;
    }
  } catch (error) {
    return {};
  } finally {
    dispatch(setLoading(false));
    return {};
  }
};

export const getGroupAttrs = (model_id: ModelId): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const group_attrs = await asyncLoadGroupAttrs(model_id);
    group_attrs && dispatch(setGroupAttrs(group_attrs));
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};
