import axios from "axios";
import { message } from "antd";
import { INode } from "../store/graphs/types";
import { IdType } from "vis-network";

type NodeAllowedUpdateFields = {
  label?: string;
  node_class_id?: string;
};

export const asyncUpdateNode = async (id: string | number, node: NodeAllowedUpdateFields) => {
  try {
    if (node) {
      const req = await axios.put(`/nodes/${id}`, node);
      message.success("Данные элемента обновлены!");
      return req.data;
    }
  } catch (error) {}
};

export const asyncAddNode = async (view_id: string, node: INode) => {
  try {
    if (node) {
      const req = await axios.post(`/views/${view_id}/nodes`, node);
      message.success("Новый элемент успешно создан!");
      return req.data;
    }
  } catch (error) {}
};

export const asyncRemoveNode = async (node_id: string) => {
  try {
    if (node_id) {
      const req = await axios.delete(`/nodes/${node_id}`);
      message.success("Элемент со связями успешно удалён!");
      return req.data;
    }
  } catch (error) {}
};

export const updatePosition = async (id: number, x: number, y: number) => {
  if (id) {
    axios.put(`/nodes/${id}`, { x, y });
    message.success("Положение элемента обновлено");
  }
};

export const asyncLoadNodeComments = async (edge_id: IdType) => {
  try {
    const res = await axios.get(`/nodes/${edge_id}/comments`);
    return res.data;
  } catch (error) {}
};

export const asyncLoadNodeByOntologyId = async (onto_id: string) => {
  try {
    const res = await axios.get(`/ontologies/${onto_id}/nodes`);
    return res.data;
  } catch (error) {}
};
