import React, { useCallback, useState } from "react";
import { Button, PageHeader, Row, Col, Table, Input, Form, Drawer, Space, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useClipboard } from "use-clipboard-copy";

import { RootState } from "../store";
import { newPublication } from "../services/views.service";
import { Link, useHistory, useParams } from "react-router-dom";
import { asyncLoadPublications } from "../store/publications/actions";
import { IPublication } from "../store/publications/types";
import { CopyOutlined } from "@ant-design/icons";

const { Column } = Table;

const Publications = () => {
  const { view_id } = useParams<{ view_id: string }>();
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const { copy } = useClipboard();
  const publications = useSelector((state: RootState) => state.publications);
  const views = useSelector((state: RootState) => state.views);
  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);
  const view = views.find((view) => view.id === view_id);

  const handleCopyLink = useCallback(
    (id: string, type: string) => {
      copy(`https://test.ontoviz.rnd.2035.university/shared/${id}/${type}`);
      message.success("Ссылка скопирована в буфер обмена!");
    },
    [copy]
  );

  const handleAdd = async (values: any) => {
    try {
      const res = await newPublication(values);
      if (res.result === "OK") {
        dispatch(asyncLoadPublications());
      }
      setIsVisibleDrawer(false);
      form.resetFields();
    } catch (error) {}
  };

  return (
    <>
      <PageHeader
        onBack={() => history.goBack()}
        title={view?.name}
        subTitle="Публикации вида"
        extra={[
          <Button key="add" type="primary" onClick={() => setIsVisibleDrawer(true)}>
            Сделать снэпшот
          </Button>,
        ]}
      />
      <Row style={{ padding: 24 }}>
        <Col span={24}>
          <Table dataSource={publications.filter((pub) => pub.view_id === view_id)} rowKey="id">
            <Column title="Название" key="name" dataIndex="name" />
            <Column
              title="Онтология"
              key="name"
              render={(_, record: IPublication) => (
                <Space>
                  <Link to={`/shared/${record.id}/ontology`} target="_blank">
                    Онтология
                  </Link>
                  <Button
                    type="link"
                    icon={<CopyOutlined />}
                    onClick={() => handleCopyLink(record.id, "ontology")}
                  />
                </Space>
              )}
            />
            <Column
              title="Сценарий"
              key="name"
              render={(_, record: IPublication) => (
                <Space>
                  <Link to={`/shared/${record.id}/scenario`} target="_blank">
                    Сценарий
                  </Link>
                  <Button
                    type="link"
                    icon={<CopyOutlined />}
                    onClick={() => handleCopyLink(record.id, "scenario")}
                  />
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
      <Drawer
        width={350}
        title="Добавить новую публикацию"
        placement="right"
        onClose={() => setIsVisibleDrawer(false)}
        visible={isVisibleDrawer}
      >
        <Form form={form} onFinish={handleAdd} initialValues={{ view_id }} layout="vertical">
          <Form.Item hidden={true} name="view_id">
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            label="Название"
            name="name"
            rules={[{ required: true, message: "Укажите название публикации" }]}
          >
            <Input placeholder="Название публикации" />
          </Form.Item>
          <Form.Item label="Описание" name="description">
            <Input.TextArea placeholder="Описание публикации" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Создать публикацию
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default Publications;
