import { Button, Form, Input, message } from "antd";
import React, { useCallback } from "react";
import { IdType } from "vis-network";

import { asyncPostComment } from "../../services/comments.service";
import { IComment } from "../../store/comments/types";
import { IGraphs } from "../../store/graphs/types";

type CommentsFormProps = {
  type: keyof IGraphs;
  itemId: IdType;
  onAdd: (comment: IComment) => void;
};

const CommentsForm: React.FC<CommentsFormProps> = ({ type, itemId, onAdd }) => {
  const [form] = Form.useForm();

  const saveComment = useCallback(
    async (values) => {
      form.getFieldInstance("text").disabled = true;
      try {
        const comment = await asyncPostComment(itemId, type, values);
        comment && onAdd(comment);
        form.getFieldInstance("text").disabled = false;
        form.resetFields();
      } catch (error) {
        message.error("Ошибка при добавлении комментария. Попробуйте позже.");
      }
    },
    [form, itemId, onAdd, type]
  );

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      form.submit();
    }
  };

  return (
    <Form form={form} onFinish={saveComment} layout="vertical">
      <Form.Item name="text" required={true}>
        <Input.TextArea placeholder="Текст комментария" onKeyDown={handleKeyDown} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Отправить
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CommentsForm;
