import React from "react";
import { ConfigProps } from "./ConfigProps";
import ColorControl from "./controls/ColorControl";
import SelectControl from "./controls/SelectControl";
import SliderControl from "./controls/SliderControl";
import SwitchControl from "./controls/SwitchControl";

const EdgesConfig: React.FC<ConfigProps> = ({ options, setOptions }) => {
  const roundnessTypes = [
    { label: "dynamic", value: "dynamic" },
    { label: "continuous", value: "continuous" },
    { label: "discrete", value: "discrete" },
    { label: "diagonalCross", value: "diagonalCross" },
    { label: "straightCross", value: "straightCross" },
    { label: "horizontal", value: "horizontal" },
    { label: "vertical", value: "vertical" },
    { label: "curvedCW", value: "curvedCW" },
    { label: "curvedCCW", value: "curvedCCW" },
  ];

  return (
    <>
      <SwitchControl
        label="Показывать стрелку к узлу"
        value={options.edges?.arrows === "to"}
        onChange={(arrow) => {
          setOptions({ ...options, edges: { ...options.edges, arrows: arrow ? "to" : "" } });
        }}
      />
      <SwitchControl
        label="Прерывистые линии"
        value={!!options.edges?.dashes}
        onChange={(dashes) => setOptions({ ...options, edges: { ...options.edges, dashes } })}
      />
      <SwitchControl
        label="Включить закругление линий"
        value={
          typeof options.edges?.smooth !== "boolean"
            ? options.edges?.smooth?.enabled
            : options.edges?.smooth
        }
        onChange={(enabled) =>
          setOptions({
            ...options,
            edges: {
              ...options.edges,
              smooth:
                typeof options.edges?.smooth !== "boolean"
                  ? { ...options.edges?.smooth, enabled }
                  : enabled,
            },
          })
        }
      />
      <SelectControl
        label="Тип закругления"
        value={typeof options.edges?.smooth === "boolean" ? "dynamic" : options.edges?.smooth?.type}
        options={roundnessTypes}
        onChange={(type) => {
          setOptions({
            ...options,
            edges: {
              ...options.edges,
              smooth:
                typeof options.edges?.smooth !== "boolean"
                  ? { ...options.edges?.smooth, type }
                  : type,
            },
          });
        }}
      />
      <SliderControl
        label="Уровень закругления"
        value={typeof options.edges?.smooth !== "boolean" ? options.edges?.smooth?.roundness : 0.5}
        onChange={(roundness: number) =>
          setOptions({
            ...options,
            edges: {
              ...options.edges,
              smooth: {
                ...(options.edges?.smooth as any),
                roundness,
              },
            },
          })
        }
        step={0.1}
        min={0}
        max={1}
      />
      <ColorControl
        label="Цвет линии"
        value={
          typeof options.edges?.color === "object"
            ? options.edges?.color?.color
            : options.edges?.color
        }
        onChange={(color) => {
          let newOption = Object.assign({}, options);
          if (typeof newOption.edges?.color === "object") {
            newOption.edges.color.color = color;
            setOptions(newOption);
          }
        }}
      />
      <ColorControl
        label="Цвет выбранной линии"
        value={
          typeof options.edges?.color === "object"
            ? options.edges?.color?.highlight
            : options.edges?.color
        }
        onChange={(color) => {
          let newOption = Object.assign({}, options);
          if (typeof newOption.edges?.color === "object") {
            newOption.edges.color.highlight = color;
            setOptions(newOption);
          }
        }}
      />
    </>
  );
};

export default React.memo(EdgesConfig);
