export interface IScenario {
  id: string;
  name: string;
  onto_id: string;
  params: {
    nodeclass_id: string;
    type: string;
    hide: string[];
  };
}
export const SET_SCENARIOS = "SCENARIOS/SET";
export const ADD_SCENARIOS = "SCENARIOS/ADD";
export const REMOVE_SCENARIOS = "SCENARIOS/REMOVE";
export const UPDATE_SCENARIOS = "SCENARIOS/UPDATE";

interface IScenarioSetAction {
  type: typeof SET_SCENARIOS;
  payload: IScenario[];
}

interface IScenarioUpdateAction {
  type: typeof UPDATE_SCENARIOS;
  payload: IScenario;
}

interface IScenarioAddAction {
  type: typeof ADD_SCENARIOS;
  payload: IScenario;
}

interface IScenarioRemoveAction {
  type: typeof REMOVE_SCENARIOS;
  payload: string;
}

export type ScenarioActionTypes =
  | IScenarioSetAction
  | IScenarioUpdateAction
  | IScenarioAddAction
  | IScenarioRemoveAction;
