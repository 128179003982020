import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { Data, DataSet, IdType, Network, Options } from "vis-network/standalone/esm/vis-network";
import { useParams } from "react-router-dom";

import { RootState } from "../store";
import { ViewId } from "../store/views/types";
import { IEdge, INode } from "../store/graphs/types";
import { EventType } from "../types/EventType";
import {
  asyncLoadGraphs,
  setGroupAttrs,
  setNodePosition,
  updateGraphs,
} from "../store/graphs/actions";
import { asyncRemoveNode, updatePosition } from "../services/nodes.service";
import { asyncAddEdge, asyncRemoveEdge } from "../services/edges.service";
import { asyncLoadGroupAttrs } from "../services/group_attrs.service";
import VisNetwork from "../components/VisNetwork";
import Legend from "../components/legend/Legend";
import CommentsManager from "../components/comments/CommentsManager";
import HistoryManager from "../components/history/HistoryManager";
import NodePanel from "../components/NodePanel";
import EdgePanel from "../components/EdgePanel";

const Ontology = () => {
  const { view_id } = useParams<{ view_id: ViewId }>();
  const dispatch = useDispatch();
  const refNetwork = useRef<Network | null>();
  const refNodes = useRef<DataSet | null>();
  const { views, presets, comments } = useSelector((state: RootState) => state);
  const { nodes, edges, group_attrs } = useSelector((state: RootState) => state.graphs);
  const [tempDataNode, setTempDataNode] = useState<INode>();
  const [tempDataEdge, setTempDataEdge] = useState<IEdge>();
  const [selectedStartedNode, setSelectedStartedNode] = useState<IdType[]>([]);
  const [isAttrsLoaded, setIsAttrsLoaded] = useState(false);

  // Получаем данные текущего вида
  const view = views.find((view) => view.id === view_id);

  /**
   * Получаем рефы visnetwork'a
   */
  const getNetwork = useCallback((network: Network) => {
    refNetwork.current = network;
  }, []);

  const getNodes = useCallback((nodes: DataSet) => {
    refNodes.current = nodes;
  }, []);

  /**
   * Фокусируемся на указанной ноде
   */
  const handleFocusNode = useCallback((node_id: IdType) => {
    refNetwork.current?.focus(node_id);
  }, []);

  /**
   * Фокусируемся на указанной ноде
   */
  const handleAddNodeToDOM = useCallback((node: INode) => {
    refNodes.current?.add(node);
  }, []);

  /**
   * Делаем ноду выбранной
   */
  const handleSelectNode = useCallback((node_id: IdType) => {
    refNetwork.current?.selectNodes([node_id]);
  }, []);

  /**
   * Делаем ноду выбранной
   */
  const handleSelectEdge = useCallback((edge_id: IdType) => {
    refNetwork.current?.selectNodes([edge_id]);
  }, []);

  /**
   * Формируем данные исходя из настроек
   */
  const data = useMemo(() => {
    // Если включен пошаговый режим
    if (view?.is_hidden_nodes_on_start) {
      // Проверяем есть ли выбранная стартовая нода
      if (selectedStartedNode.length > 0) {
        // Ищем все связи подряд
        const filteredAllEdges = edges.filter(
          (edge) =>
            selectedStartedNode.includes(edge.from!) || selectedStartedNode.includes(edge.to!)
        );
        // Ищем все ноды относящиеся к связям
        const filteredNodes = nodes.filter(
          (node) =>
            filteredAllEdges.some((e) => e.from === node.id) ||
            filteredAllEdges.some((e) => e.to === node.id)
        );
        // Возвращаем отфильтрованные ноды и связи
        return {
          nodes: filteredNodes,
          edges: filteredAllEdges,
        };
      } else {
        // Возвращаем пустой граф
        return { nodes: [], edges: [] };
      }
    } else {
      return { nodes, edges };
    }
  }, [edges, nodes, selectedStartedNode, view?.is_hidden_nodes_on_start]);

  /*
   * Указываем дефолтные настройки
   */
  const options = useMemo(() => {
    const defaultOptions: Options = {
      autoResize: true,
      height: "100%",
      width: "100%",
      locale: "ru",
      manipulation: {
        enabled: view?.is_editable,
        addNode: async (node: INode, callback: any) => {
          setTempDataNode({ ...node, isNew: true });
          callback();
        },
        addEdge: async (edge: IEdge, callback: any) => {
          if (edge.from !== edge.to) {
            asyncAddEdge(view_id, edge);
            callback(edge);
          }
        },
        editNode: async (node: INode, callback: any) => {
          if (!view) return;
          setTempDataNode({
            id: node.id,
            onto_id: view?.model_id,
            label: node.label,
            node_class_id: node.node_class_id,
            model_node_id: node.model_node_id,
          });
          callback(node);
        },
        editEdge: async (edge: IEdge, callback: any) => {
          callback(edge);
        },
        deleteNode: (data: Data, callback: any) => {
          data.nodes.forEach((node_id: string) => {
            asyncRemoveNode(node_id);
          });
          callback(data);
        },
        deleteEdge: (data: Data, callback: any) => {
          data.edges.forEach((edge_id: string) => {
            asyncRemoveEdge(edge_id);
          });
          callback(data);
        },
      },
      layout: {
        randomSeed: 1,
        improvedLayout: true,
        hierarchical: {
          enabled: true,
          direction: "LR",
          sortMethod: "directed",
        },
      },
      physics: {
        enabled: true,
        solver: "barnesHut",
        barnesHut: {
          avoidOverlap: 0.5,
          springLength: 100,
        },
        forceAtlas2Based: {
          avoidOverlap: 0.5,
          springLength: 100,
        },
        repulsion: {
          nodeDistance: 100,
        },
        hierarchicalRepulsion: {
          avoidOverlap: 0.5,
          nodeDistance: 100,
        },
      },
      nodes: {
        shape: "box",
        shadow: false,
        opacity: 1,
        color: "#97C2FC",
      },
    };
    if (view) {
      // Получаем пресет
      const preset = presets.find((p) => p.id === view.preset_id);
      if (preset?.content) {
        // перезаписываем дефолтные настройки пресетом
        return { ...defaultOptions, ...preset.content };
      } else {
        return defaultOptions;
      }
    }
  }, [view, view_id, presets]);

  /**
   * Убираем лишнее при размонтировании компонента
   */
  useEffect(() => {
    return () => {
      // убираем панель информации о выбранной ноде
      setTempDataNode(undefined);
      setTempDataEdge(undefined);
      // удаляем информацию о нодах и связях
      dispatch(updateGraphs([], []));
    };
  }, [dispatch]);

  /**
   * Подгружаем групповые атрибуты для фильтрации
   */
  useEffect(() => {
    if (!view) return;
    (async () => {
      const group_attrs = await asyncLoadGroupAttrs(view.model_id);
      if (group_attrs) {
        dispatch(setGroupAttrs(group_attrs));
        setIsAttrsLoaded(true);
      }
    })();
  }, [dispatch, view]);

  /**
   * Подгружаем ассинхронно данные онтологии
   */
  useEffect(() => {
    isAttrsLoaded && dispatch(asyncLoadGraphs(view_id, group_attrs));
  }, [dispatch, view_id, group_attrs, isAttrsLoaded]);

  /**
   * Задаем события для VisNetwork
   */
  const events: EventType = useMemo(() => {
    return {
      select: (params: any) => {
        if (params.nodes.length > 0) {
          // Получаем его идентификатор
          const nodeId = params.nodes[0];
          // Ищем его в массиве
          const node = nodes.find((n) => n.id === nodeId);
          // Записываем во временные данные
          setTempDataNode(node);
        } else {
          // Получаем его идентификатор
          const edgeId = params.edges[0];
          // Ищем его в массиве
          const edge = edges.find((n) => n.id === edgeId);
          // Записываем во временные данные
          setTempDataEdge(edge);
        }
      },
      dragEnd: (params: any) => {
        const id = params.nodes[0];
        // Получаем параметры ноды
        const { x, y } = params.pointer.canvas;
        // Отправляем новые параметры на сервер
        updatePosition(id, x, y);
        // Сохраняем данные ноды в хранилище
        dispatch(setNodePosition(id, x, y));
      },
      doubleClick: (params: any) => {
        const id = params.nodes[0];
        if (!selectedStartedNode.includes(id)) {
          setSelectedStartedNode([...selectedStartedNode, id]);
        }
      },
    };
  }, [dispatch, edges, nodes, selectedStartedNode]);

  /**
   * Активируем и центрируем узел или связь выбранную в комментариях
   */
  const handleSelectComment = useCallback(
    (model_item_id: string) => {
      // получаем узел комментария
      const findNodeByModelItemId = nodes.find((node) => node.model_node_id === model_item_id);
      // получаем связь комментариях
      const findEdgeByModelItemId = edges.find((edge) => edge.model_edge_id === model_item_id);
      // Если есть узел, синтезируем со списком старовых узлов
      if (findNodeByModelItemId?.id) {
        if (!selectedStartedNode.includes(findNodeByModelItemId.id)) {
          setSelectedStartedNode([...selectedStartedNode, findNodeByModelItemId.id]);
        } else {
          handleSelectNode(findNodeByModelItemId.id);
          handleFocusNode(findNodeByModelItemId.id);
        }
      }
      // Если есть связь, фокусируемся на ней
      if (findEdgeByModelItemId?.id) {
        handleSelectEdge(findEdgeByModelItemId.id);
      }
    },
    [nodes, edges, selectedStartedNode, handleSelectNode, handleFocusNode, handleSelectEdge]
  );

  return (
    <>
      {view?.is_hidden_nodes_on_start && (
        <div className="ontology-filter">
          <Select
            mode="multiple"
            showSearch
            autoFocus
            filterOption
            optionFilterProp="label"
            style={{ width: "100%" }}
            placeholder="Выберите стартовый узел"
            value={selectedStartedNode}
            options={nodes.map((node) => {
              return { label: node.label!, value: node.id! as string };
            })}
            onChange={(value) => setSelectedStartedNode(value)}
          />
        </div>
      )}
      {group_attrs.length > 0 && <Legend group_attrs={group_attrs} presets={presets} />}
      {view && (
        <VisNetwork
          events={events}
          data={data}
          options={options}
          getNetwork={getNetwork}
          getNodes={getNodes}
        />
      )}
      {view && (
        <CommentsManager
          onto_id={view.model_id}
          comments={comments}
          onSelect={handleSelectComment}
        />
      )}
      {view && <HistoryManager view_id={view.id} />}
      {tempDataNode && (
        <NodePanel
          onSelectComment={handleSelectComment}
          onFocusNode={handleFocusNode}
          onAddNode={handleAddNodeToDOM}
          view_id={view_id}
          tempDataNode={tempDataNode}
          setTempDataNode={setTempDataNode}
        />
      )}
      {tempDataEdge && (
        <EdgePanel
          tempDataEdge={tempDataEdge}
          setTempDataEdge={setTempDataEdge}
          onSelectComment={handleSelectComment}
        />
      )}
    </>
  );
};

export default Ontology;
