import {
  ADD_PUBLICATIONS,
  REMOVE_PUBLICATIONS,
  IPublication,
  PublicationActionTypes,
  SET_PUBLICATIONS,
  UPDATE_PUBLICATIONS,
} from "./types";

const initialState: IPublication[] = [];
export function publicationsReducer(
  state = initialState,
  action: PublicationActionTypes
): IPublication[] {
  switch (action.type) {
    case SET_PUBLICATIONS:
      return action.payload;
    case ADD_PUBLICATIONS:
      return [...state, action.payload];
    case REMOVE_PUBLICATIONS:
      return state.filter((preset) => preset.id !== action.payload);
    case UPDATE_PUBLICATIONS:
      return state.map((item) => (item.id === action.payload.id ? action.payload : item));
    default:
      return state;
  }
}
