import React, { useEffect } from "react";
import { Button, Col, Collapse, PageHeader, Row, Select, Table } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store";
import { getGroupAttrs, setGroupAttrsPriority, updateGroupAttr } from "../store/graphs/actions";

import { saveGroupAttrs } from "../services/group_attrs.service";

import { IGroupAttrs } from "../store/graphs/types";
import { ViewId } from "../store/views/types";

const { Column } = Table;
const { Panel } = Collapse;
const { Option } = Select;

const Attributes = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { view_id } = useParams<{ view_id: ViewId }>();
  const views = useSelector((state: RootState) => state.views);
  const presets = useSelector((state: RootState) => state.presets);
  const group_attrs = useSelector((state: RootState) => state.graphs.group_attrs);
  const view = views.find((view) => view.id === view_id);

  console.log("group_attrs :>> ", group_attrs);

  /**
   * Асинхронная загрузка групповых атрибутов по view_id
   */
  useEffect(() => {
    view && dispatch(getGroupAttrs(view.model_id));
  }, [dispatch, view]);

  /**
   * Назначаем новый пресет для значения атрибута
   * @param attr данные группового атрибута
   * @param value значение атрибута
   * @param preset ID пресета
   */
  const handleChangePreset = (attr: IGroupAttrs, value: string, preset: string) => {
    // получаем список значений атрибутов с изменениями
    const updatedValues = attr.values.map((a) => {
      if (a.value === value) {
        return { ...a, preset: preset ? preset : "" };
      } else {
        return a;
      }
    });
    // Сохраняем изменения в хранилище
    dispatch(updateGroupAttr(attr.id, "values", updatedValues));
    // Отправляем новые данные на сервер
    saveGroupAttrs(attr.id, "values", updatedValues);
  };

  const handleUpPosition = (attr: IGroupAttrs) => {
    dispatch(setGroupAttrsPriority(attr.id, attr.priority - 1));
    saveGroupAttrs(attr.id, "priority", attr.priority - 1);
  };

  const handleDownPosition = (attr: IGroupAttrs) => {
    dispatch(setGroupAttrsPriority(attr.id, attr.priority + 1));
    saveGroupAttrs(attr.id, "priority", attr.priority + 1);
  };

  return (
    <>
      <PageHeader
        onBack={() => history.goBack()}
        title="Настройка группирующих атрибутов"
        subTitle={`Для вида ${view?.name}`}
      />
      <Row style={{ padding: 24 }}>
        <Col span={24}>
          <Collapse ghost accordion>
            {group_attrs
              .sort((a, b) => (a.priority > b.priority ? 1 : -1))
              .map((item) => (
                <Panel key={item.id} header={`${item.visual_name}`}>
                  <Table
                    size="middle"
                    rowKey="id"
                    pagination={false}
                    dataSource={item.values.filter((attr) => attr.value !== "")}
                    title={() => (
                      <Button.Group key="priority_buttons">
                        <Button size="small" type="text" key="priority">
                          Приоритет
                        </Button>
                        <Button
                          size="small"
                          icon={<UpOutlined />}
                          onClick={() => handleUpPosition(item)}
                          key="up"
                        />
                        <Button
                          size="small"
                          icon={<DownOutlined />}
                          onClick={() => handleDownPosition(item)}
                          key="down"
                        />
                      </Button.Group>
                    )}
                  >
                    <Column title="Значение" dataIndex="value" key="value" />
                    <Column
                      title="Пресет"
                      key="preset_id"
                      render={(_: any, record: any) => {
                        return (
                          <Select
                            placeholder="Выберите пресет"
                            allowClear
                            value={record.preset}
                            style={{ width: 250 }}
                            onChange={(preset_id) =>
                              handleChangePreset(item, record.value, preset_id)
                            }
                          >
                            {presets
                              .filter((p) => p.onto_id === view?.model_id)
                              .map((preset) => (
                                <Option key={preset.id} value={preset.id}>
                                  {preset.name}
                                </Option>
                              ))}
                          </Select>
                        );
                      }}
                    />
                  </Table>
                </Panel>
              ))}
          </Collapse>
        </Col>
      </Row>
    </>
  );
};

export default Attributes;
