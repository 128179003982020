export const SET_LOADING = "APP/SET/LOADING";
export const SET_REDIRECT = "APP/SET/REDIRECT";

interface IRedirectSetAction {
  type: typeof SET_REDIRECT;
  payload?: string;
}

interface ILoadingSetAction {
  type: typeof SET_LOADING;
  payload: boolean;
}

export type AppActionTypes = ILoadingSetAction | IRedirectSetAction;

export interface AppState {
  isLoading: boolean;
  errors?: string[];
  redirect?: string;
}
