import { AppState, AppActionTypes, SET_LOADING, SET_REDIRECT } from "./types";

const initialState: AppState = {
  isLoading: false,
  errors: [],
};
export function appReducer(state = initialState, action: AppActionTypes): AppState {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_REDIRECT:
      return { ...state, redirect: action.payload };
    default:
      return state;
  }
}
