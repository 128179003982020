import axios from "axios";
import { UserState } from "../store/auth/types";

export const whoami = async (token: string): Promise<UserState | undefined> => {
  try {
    const req = await axios("/users/whoami", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return req.data;
  } catch (error) {
    localStorage.removeItem("sso_token_leader_id");
  }
};
