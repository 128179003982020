import { HistoryOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import React, { useState } from "react";
import History from "./History";

type HistoryManagerProps = {
  view_id: string;
};

const HistoryManager: React.FC<HistoryManagerProps> = ({ view_id }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <div className="history">
        <Button
          type="primary"
          size="large"
          onClick={() => setIsVisible(!isVisible)}
          shape="circle"
          icon={<HistoryOutlined style={{ fontSize: "2rem" }} />}
        />
      </div>
      <Drawer
        width={400}
        title="История изменений"
        placement="right"
        onClose={() => setIsVisible(!isVisible)}
        visible={isVisible}
        mask={false}
      >
        <History view_id={view_id} />
      </Drawer>
    </>
  );
};

export default React.memo(HistoryManager);
