import { SET_LOG_IN, UserState, AuthActionTypes, SET_LOG_OUT } from "./types";

export function setLogIn(user: UserState): AuthActionTypes {
  return {
    type: SET_LOG_IN,
    payload: user,
  };
}

export function setLogOut(): AuthActionTypes {
  return {
    type: SET_LOG_OUT,
  };
}
