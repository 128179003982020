import { List, Comment, Button } from "antd";
import dayjs from "dayjs";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../../store";
import { asyncRemoveComment } from "../../services/comments.service";
import { IComment } from "../../store/comments/types";
import { removeComments } from "../../store/comments/actions";

type CommentsProps = {
  comments: IComment[];
  onSelect: (model_item_id: string) => void;
};

const Comments: React.FC<CommentsProps> = ({ comments, onSelect }) => {
  const dispatch = useDispatch();
  const { leader_id } = useSelector((state: RootState) => state.auth);
  const editors = useSelector((state: RootState) => state.editors);

  /**
   * Удаляем комментарий по клику на кнопку
   */
  const handleRemoveComment = useCallback(
    async (comment: IComment) => {
      // Получаем статус удаления с сервера
      const isDeleted = await asyncRemoveComment(comment.id);
      // Удаляем из хранилища комментарий
      isDeleted && dispatch(removeComments(comment.id));
    },
    [dispatch]
  );

  return (
    <>
      <List
        className="comment-list"
        itemLayout="horizontal"
        dataSource={comments?.sort((a, b) =>
          dayjs(a.update_time) < dayjs(b.update_time) ? 1 : -1
        )}
        renderItem={(comment) => {
          const editor = editors.find((e) => e.user.leader_id === comment.author_id);
          return (
            <li>
              <Comment
                actions={[
                  <span onClick={() => onSelect(comment.model_item_id)}>{comment.item_title}</span>,
                  leader_id === comment.author_id ? (
                    <Button size="small" type="link" onClick={() => handleRemoveComment(comment)}>
                      Удалить
                    </Button>
                  ) : undefined,
                ]}
                author={
                  <a
                    rel="noreferrer"
                    href={`https://leader-id.ru/users/${comment.author_id}`}
                    target="_blank"
                  >
                    {editor?.user.firstname}
                  </a>
                }
                avatar={editor?.user.photo_urls.Small}
                content={comment.text}
                datetime={dayjs(comment.update_time).format("DD.MM.YYYY HH:mm")}
              />
            </li>
          );
        }}
      />
    </>
  );
};

export default React.memo(Comments);
