import { Button, Collapse, Drawer, Space } from "antd";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IdType } from "vis-network";

import { asyncAddNode, asyncUpdateNode } from "../services/nodes.service";

import { RootState } from "../store";
import { addComments } from "../store/comments/actions";
import { IComment } from "../store/comments/types";
import { addNode, updateNode } from "../store/graphs/actions";
import { INode } from "../store/graphs/types";

import Comments from "./comments/Comments";
import CommentsForm from "./comments/CommentsForm";
import LinkedNode from "./LinkedNode";
import NodeEditor from "./NodeEditor";

const { Panel } = Collapse;

type NodePanelProps = {
  view_id: string;
  tempDataNode: INode;
  onAddNode: (node: INode) => void;
  onFocusNode: (id: IdType) => void;
  onSelectComment: (model_item_id: string) => void;
  setTempDataNode: (node: INode | undefined) => void;
};

const NodePanel: React.FC<NodePanelProps> = ({
  view_id,
  tempDataNode,
  setTempDataNode,
  onSelectComment,
  onAddNode,
  onFocusNode,
}) => {
  const dispatch = useDispatch();
  const { nodeclasses, comments } = useSelector((state: RootState) => state);
  const { nodes, edges } = useSelector((state: RootState) => state.graphs);

  // Получаем групповые параметры выбранного узла
  const group_params = nodes.find((n) => n.id === tempDataNode?.id)?.group_params;

  // Отключаем кнопку сохранения если узел новый и не указан класс
  const isDisabled = () => {
    return tempDataNode?.isNew && !tempDataNode?.node_class_id;
  };

  /**
   * Сохраняем данные узла на сервере
   */
  const handleSaveDataNode = () => {
    if (!tempDataNode) return;
    // Проверяем новая эта нода или нет
    if (tempDataNode.isNew) {
      // Если новая удаляем это обозначение
      delete tempDataNode.isNew;
      // Сохраняем данные на сервер
      asyncAddNode(view_id, tempDataNode);
      // Добавляем в хранилище
      dispatch(addNode(tempDataNode));
      // Добавляем ноду в DOM
      onAddNode(tempDataNode);
      setTempDataNode(undefined);
    } else {
      // Обновляем ноду на сервере
      asyncUpdateNode(tempDataNode.id!, {
        label: tempDataNode.label,
        node_class_id: tempDataNode.node_class_id,
      });
      // Обновляем ноду в хранилище
      dispatch(updateNode(tempDataNode));
    }
    // Фокусируемся на обновленной ноде
    onFocusNode(tempDataNode.id!);
  };

  /**
   * Получаем детей выбранного узла
   */
  const childrenLinks = useMemo(() => {
    const selectedEdges = edges
      .filter((edge) => edge.from === tempDataNode?.id)
      .map((edge) => edge.to);
    return nodes.filter((node) => selectedEdges.includes(node.id));
  }, [edges, nodes, tempDataNode?.id]);

  /**
   * Получаем родителей выбранного узла
   */
  const parentsLinks = useMemo(() => {
    const selectedEdges = edges
      .filter((edge) => edge.to === tempDataNode?.id)
      .map((edge) => edge.from);
    return nodes.filter((node) => selectedEdges.includes(node.id));
  }, [edges, nodes, tempDataNode?.id]);

  //Добавляем новый комментарий
  const handleAddComment = (comment: IComment) => dispatch(addComments(comment));

  //Фильтруем комментарии узлов и связей
  const filteredComments = comments.filter((c) => c.model_item_id === tempDataNode.model_node_id);

  return (
    <Drawer
      width={400}
      title="Данные выбранного элемента"
      placement="right"
      onClose={() => setTempDataNode(undefined)}
      visible={!!tempDataNode}
      mask={false}
      footer={
        <Space>
          <Button type="primary" onClick={handleSaveDataNode} disabled={isDisabled()}>
            Сохранить изменения
          </Button>
        </Space>
      }
    >
      <NodeEditor node={tempDataNode} onChange={setTempDataNode} nodeclasses={nodeclasses} />
      <Collapse defaultActiveKey={["group_params"]} ghost>
        {group_params && (
          <Panel header="Групповые атрибуты" key="group_params">
            {group_params.map((param) => (
              <p key={param.name}>
                {param.visualname || param.name}: {param.value}
              </p>
            ))}
          </Panel>
        )}
        {parentsLinks.length > 0 && (
          <Panel header={`Родители (${parentsLinks.length})`} key="parents">
            {parentsLinks.map((link) => (
              <LinkedNode key={link.id} link={link} onFocus={onFocusNode} />
            ))}
          </Panel>
        )}
        {childrenLinks.length > 0 && (
          <Panel header={`Потомки (${childrenLinks.length})`} key="children">
            {childrenLinks.map((link) => (
              <LinkedNode key={link.id} link={link} onFocus={onFocusNode} />
            ))}
          </Panel>
        )}
        <Panel header={`Комментарии (${filteredComments.length || 0})`} key="comments">
          <Comments comments={filteredComments} onSelect={onSelectComment} />
          <CommentsForm type="nodes" itemId={tempDataNode.id!} onAdd={handleAddComment} />
        </Panel>
      </Collapse>
    </Drawer>
  );
};

export default NodePanel;
