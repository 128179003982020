import React, { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Button,
  PageHeader,
  Select,
  Row,
  Col,
  Table,
  Input,
  Space,
  Form,
  Drawer,
  Checkbox,
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { IView } from "../store/views/types";
import { getViews, updateView } from "../store/views/actions";
import { newView, saveView } from "../services/views.service";
import { PresetId } from "../store/presets/types";

const { Column } = Table;

const Views = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { onto_id } = useParams<{ onto_id: string }>();
  const [form] = Form.useForm();
  const { views, presets } = useSelector((state: RootState) => state);
  const [isVisibleDrawer, setIsVisibleDrawer] = useState(false);

  const handleAdd = async (values: any) => {
    const res = await newView(onto_id, values);
    if (res.result === "OK") {
      dispatch(getViews());
    }
    setIsVisibleDrawer(false);
    form.resetFields();
  };
  /**
   * TODO: реализовать удаление вида
   * Удаляем вид онтологии
   * @param id ид вида
   */
  const handleRemove = (id: string) => {};

  /**
   * Назначаем новый пресет для вида
   * @param view данные вида
   * @param preset_id ид пресета
   */
  const handleChangePreset = (view: IView, preset_id: PresetId) => {
    // Обновляем хранилище
    dispatch(updateView({ ...view, preset_id }));
    // Обновляем вид на сервере
    saveView(view.id, "preset_id", preset_id);
  };

  /**
   * Изменяем редактируемость вида
   * @param view данные вида
   * @param preset_id ид пресета
   */
  const handleChangeEditable = (view: IView, is_editable: boolean) => {
    // Обновляем хранилище
    dispatch(updateView({ ...view, is_editable }));
    // Обновляем вид на сервере
    saveView(view.id, "is_editable", is_editable);
  };

  /**
   * Изменяем параметр поэтапного режима
   * @param view данные вида
   * @param preset_id ид пресета
   */
  const handleChangeHiddenMode = (view: IView, is_hidden_nodes_on_start: boolean) => {
    // Обновляем хранилище
    dispatch(updateView({ ...view, is_hidden_nodes_on_start }));
    // Обновляем вид на сервере
    saveView(view.id, "is_hidden_nodes_on_start", is_hidden_nodes_on_start);
  };

  /**
   * Меняем значение имени вида
   * @param view данные вида
   * @param name новое имя
   */
  const handleChangeName = (view: IView, name: string) => {
    dispatch(updateView({ ...view, name }));
  };

  /**
   * Сохраняем новое имя на сервере
   * @param view данные вида
   */
  const handleSaveName = (view: IView) => {
    saveView(view.id, "name", view.name);
  };

  const filteredViews = views
    .filter((v) => v.model_id === onto_id)
    .filter((v) => v.is_publication === false);

  return (
    <>
      <PageHeader
        onBack={() => history.goBack()}
        title="Виды"
        subTitle="Настройка видов онтологии"
        extra={[
          <Button key="add" type="primary" onClick={() => setIsVisibleDrawer(true)}>
            Добавить вид
          </Button>,
        ]}
      />
      <Row style={{ padding: 24 }}>
        <Col span={24}>
          <Table dataSource={filteredViews} rowKey="id">
            <Column
              title="Название"
              key="name"
              render={(text, record: IView) => (
                <Input
                  bordered={false}
                  value={record.name}
                  onChange={(e) => handleChangeName(record, e.target.value)}
                  onBlur={() => handleSaveName(record)}
                />
              )}
            />
            <Column
              title="Пресет"
              key="preset"
              render={(_, record: IView) => (
                <Select
                  style={{ width: 200 }}
                  options={presets
                    .filter((p) => p.onto_id === onto_id)
                    .map((preset) => {
                      return {
                        label: preset.name,
                        value: preset.id,
                      };
                    })}
                  value={record.preset_id}
                  onChange={(value) => handleChangePreset(record, value)}
                />
              )}
            />
            <Column
              title="Поэтапный режим"
              key="is_hidden_nodes_on_start"
              render={(_, record: IView) => (
                <Checkbox
                  checked={record.is_hidden_nodes_on_start}
                  onChange={(e) => handleChangeHiddenMode(record, e.target.checked)}
                />
              )}
            />
            <Column
              title="Редактируемый"
              key="is_editable"
              render={(_, record: IView) => (
                <Checkbox
                  checked={record.is_editable}
                  onChange={(e) => handleChangeEditable(record, e.target.checked)}
                />
              )}
            />
            <Column
              title="Действие"
              key="action"
              render={(text, record: IView) => (
                <Space align="end">
                  <Link to={`/attributes/${record.id}`}>
                    <Button type="primary">Атрибуты</Button>
                  </Link>
                  <Link to={`/views/${record.id}/publications`}>
                    <Button type="primary">Публикации</Button>
                  </Link>
                  <Button type="primary" danger onClick={() => handleRemove(record.id)}>
                    Удалить
                  </Button>
                </Space>
              )}
            />
          </Table>
        </Col>
      </Row>
      <Drawer
        width={350}
        title="Добавить новый вид"
        placement="right"
        onClose={() => setIsVisibleDrawer(false)}
        visible={isVisibleDrawer}
      >
        <Form form={form} onFinish={handleAdd} layout="vertical">
          <Form.Item
            label="Название"
            name="view_name"
            rules={[{ required: true, message: "Укажите название вида" }]}
          >
            <Input placeholder="Название вида" />
          </Form.Item>
          <Form.Item label="Ссылка на предпросмотр" name="link">
            <Input placeholder="show_must_go_on" />
          </Form.Item>
          <Form.Item label="Описание" name="description">
            <Input.TextArea placeholder="Описание вида" />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Добавить вид
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default Views;
