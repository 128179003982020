import axios from "axios";
import { AppThunk } from "..";
import { setLoading } from "../app/actions";
import {
  SET_PUBLICATIONS,
  ADD_PUBLICATIONS,
  REMOVE_PUBLICATIONS,
  UPDATE_PUBLICATIONS,
  PublicationActionTypes,
  IPublication,
} from "./types";

export function setPublications(publications: IPublication[]): PublicationActionTypes {
  return {
    type: SET_PUBLICATIONS,
    payload: publications,
  };
}

export function addPublications(publication: IPublication): PublicationActionTypes {
  return {
    type: ADD_PUBLICATIONS,
    payload: publication,
  };
}

export function updatePublications(publication: IPublication): PublicationActionTypes {
  return {
    type: UPDATE_PUBLICATIONS,
    payload: publication,
  };
}

export function removePublications(publication_id: string): PublicationActionTypes {
  return {
    type: REMOVE_PUBLICATIONS,
    payload: publication_id,
  };
}

export const asyncLoadPublications = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const req = await axios(`/publications`);
    if (req.status === 200) {
      dispatch(setPublications(req.data));
    }
  } catch (error) {
    //dispatch(setUuid(""));
  } finally {
    dispatch(setLoading(false));
  }
};
