import { IGroupAttrs } from "../store/graphs/types";

/**
 * Собираем строку url параметров из групповых атрибутов
 * @param group_attrs групповые атрибуты
 * @returns
 */
export const filtersQueryString = (group_attrs: IGroupAttrs[]): string => {
  return group_attrs
    .filter((ga) => ga.value) // убираем те что не имеют значения
    .reduce((acc, item, index) => {
      // Устанавливаем корректный символ для параметра
      index === 0 ? (acc += "?") : (acc += "&");
      // Формируем строку key=value
      acc += `${item.name}=${item.value}`;
      // Возвращаем конечный результат
      return acc;
    }, ``);
};
