import { IView } from "../views/types";

export interface IPublication extends IView {
  view_id: string;
}

export const SET_PUBLICATIONS = "PUBLICATIONS/SET";
export const ADD_PUBLICATIONS = "PUBLICATIONS/ADD";
export const REMOVE_PUBLICATIONS = "PUBLICATIONS/REMOVE";
export const UPDATE_PUBLICATIONS = "PUBLICATIONS/UPDATE";

interface IPublicationSetAction {
  type: typeof SET_PUBLICATIONS;
  payload: IPublication[];
}

interface IPublicationUpdateAction {
  type: typeof UPDATE_PUBLICATIONS;
  payload: IPublication;
}

interface IPublicationAddAction {
  type: typeof ADD_PUBLICATIONS;
  payload: IPublication;
}

interface IPublicationRemoveAction {
  type: typeof REMOVE_PUBLICATIONS;
  payload: string;
}

export type PublicationActionTypes =
  | IPublicationSetAction
  | IPublicationUpdateAction
  | IPublicationAddAction
  | IPublicationRemoveAction;
