import axios from "axios";
import { AppThunk } from "..";
import { setLoading } from "../app/actions";
import {
  SET_SCENARIOS,
  ADD_SCENARIOS,
  REMOVE_SCENARIOS,
  UPDATE_SCENARIOS,
  ScenarioActionTypes,
  IScenario,
} from "./types";

export function setScenario(presets: IScenario[]): ScenarioActionTypes {
  return {
    type: SET_SCENARIOS,
    payload: presets,
  };
}

export function addScenario(preset: IScenario): ScenarioActionTypes {
  return {
    type: ADD_SCENARIOS,
    payload: preset,
  };
}

export function updateScenario(preset: IScenario): ScenarioActionTypes {
  return {
    type: UPDATE_SCENARIOS,
    payload: preset,
  };
}

export function removeScenario(preset_id: string): ScenarioActionTypes {
  return {
    type: REMOVE_SCENARIOS,
    payload: preset_id,
  };
}

export const asyncLoadScenarios = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const req = await axios(`/scenarios`);
    if (req.status === 200) {
      dispatch(setScenario(req.data));
    }
  } catch (error) {
    //dispatch(setUuid(""));
  } finally {
    dispatch(setLoading(false));
  }
};
