import React from "react";
import { Switch } from "antd";
import { ControlProps } from "./ControlProps";

const SwitchControl: React.FC<ControlProps> = ({ label, value, onChange }) => {
  return (
    <div className="filter__item">
      <Switch checked={value} onChange={onChange} /> {label}
    </div>
  );
};

export default SwitchControl;
