import {
  SET_COMMENTS,
  UPDATE_COMMENTS,
  ADD_COMMENTS,
  REMOVE_COMMENTS,
  IComment,
  CommentsActionTypes,
} from "./types";

const initialState: IComment[] = [];
export function commentsReducer(state = initialState, action: CommentsActionTypes): IComment[] {
  switch (action.type) {
    case SET_COMMENTS:
      return action.payload;
    case ADD_COMMENTS:
      return [...state, action.payload];
    case REMOVE_COMMENTS:
      return state.filter((item) => item.id !== action.payload);
    case UPDATE_COMMENTS:
      return state.map((item) => (item.id === action.payload.id ? action.payload : item));
    default:
      return state;
  }
}
