import { Dropdown, Modal } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { qsOntoParams } from "../../helpers/qsOntoParams";
import { useUniqueArray } from "../../hooks/useUniqueArray";
import { RootState } from "../../store";
import { IOntology } from "../../store/ontologies/types";
import { IScenario } from "../../store/scenarios/types";
import { IView } from "../../store/views/types";
import OntologyBlockButtons from "./OntologyBlockButtons";
import OntologyDuplicate from "./OntologyDuplicate";
import RemoveOntology from "./RemoveOntology";
import ScenarioModal from "./ScenarioModal";
import Scenarios from "./Scenarios";

type OntologyBlockProps = {
  ontology: IOntology;
  views: IView[];
};

const OntologyBlock: React.FC<OntologyBlockProps> = ({ ontology, views }) => {
  const history = useHistory();
  const { presets, scenarios, auth, editors, ontologies } = useSelector(
    (state: RootState) => state
  );
  const [, addVisible, removeVisible, hasVisible] = useUniqueArray();
  const [selectedScenarios, addScenario, removeScenario] = useUniqueArray<string>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedView, setSelectedView] = useState<IView>();
  const [selectedScenario, setSelectedScenario] = useState<IScenario>();
  const [duplicate, setDuplicate] = useState<IOntology>();
  const [removable, setRemovable] = useState<IOntology>();

  const filteredViews = views
    .filter((view) => view.model_id === ontology.id)
    .filter((view) => view.is_publication === false);

  const presetsCount = presets.filter((preset) => preset.onto_id === ontology.id).length;
  const editorsCount = editors.filter((editor) => editor.onto_id === ontology.id).length;
  const viewsCount = filteredViews.length;

  /**
   * Переходим на выбранный вид с параметрами сценария
   */
  const handleOk = () => {
    setIsModalVisible(false);
    const params = selectedScenario?.params;
    const qs = qsOntoParams(selectedScenarios, params?.hide || []);
    /* const nodes = selectedScenarios.reduce((url, param) => (url += `&nodes=${param}`), "");
    const hide = params?.hide.reduce((url, param) => (url += `&hide=${param}`), ""); */
    history.push(`/scenario/${selectedView?.id}?type=${params?.type}${qs}`);
  };

  /**
   * Скрываем всплывающее окно
   */
  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedView(undefined);
    setSelectedScenario(undefined);
  };

  /**
   * Функция отображения всплывающего окна
   * @param scenario Сценарий
   * @param view Вид
   */
  const handleOpen = (scenario: IScenario, view: IView) => {
    // Показываем модальное окно
    setIsModalVisible(true);
    // Убираем дропдаун
    removeVisible(view.id);
    // Записываем данные выбранного view
    setSelectedView(view);
    // Записываем данные выбранного сценария
    setSelectedScenario(scenario);
  };

  const handleVisibleDropdowns = (visible: boolean, view_id: string) => {
    if (visible) {
      addVisible(view_id);
    } else {
      removeVisible(view_id);
    }
  };

  const handleSelectToCopy = () => {
    setDuplicate(ontology);
  };

  const handleSelectToDelete = () => {
    setRemovable(ontology);
  };

  const handleCompleted = () => {
    setDuplicate(undefined);
  };

  return (
    <div className="ontologies__block">
      <div className="ontologies__block-title title">
        <div className="title__name">
          <h3>{ontology.name}</h3>
        </div>
        <OntologyBlockButtons
          ontologies={ontologies}
          role={auth.role}
          ontology={ontology}
          onSelectToCopy={handleSelectToCopy}
          onSelectToDelete={handleSelectToDelete}
          presetsCount={presetsCount}
          viewsCount={viewsCount}
          editorsCount={editorsCount}
        />
      </div>
      {filteredViews.map((view) => (
        <Dropdown
          key={view.id}
          trigger={["click"]}
          visible={hasVisible(view.id)}
          onVisibleChange={(visible) => handleVisibleDropdowns(visible, view.id)}
          overlay={
            <Scenarios
              onOpen={handleOpen}
              view={view}
              scenarios={scenarios.filter((s) => s.onto_id === ontology.id)}
            />
          }
        >
          <div className="ontologies__block-item ontology">
            <div className="ontology__name">{view.name}</div>
            <div className="ontology__preset">
              {presets.find((preset) => view.preset_id === preset.id)?.name}
            </div>
          </div>
        </Dropdown>
      ))}
      <Modal
        className="duplicate-modal"
        title={`Копировать ${duplicate?.name}`}
        visible={!!duplicate}
        onCancel={() => setDuplicate(undefined)}
        footer={null}
        width={600}
      >
        {duplicate && <OntologyDuplicate ontology={duplicate} onCompleted={handleCompleted} />}
      </Modal>
      <Modal
        className="remove-modal"
        title={`Удалить ${removable?.name}?`}
        visible={!!removable}
        onCancel={() => setRemovable(undefined)}
        footer={null}
        width={600}
      >
        <RemoveOntology ontology={ontology} onCancel={() => setRemovable(undefined)} />
      </Modal>
      <Modal
        title={selectedScenario?.name}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Показать"
        cancelText="Отмена"
        okButtonProps={{
          disabled: selectedScenarios.length === 0,
        }}
      >
        <ScenarioModal
          view={selectedView}
          scenario={selectedScenario}
          onSelectScenario={addScenario}
          onUnselectScenario={removeScenario}
        />
      </Modal>
    </div>
  );
};

export default OntologyBlock;
