import {
  IGraphs,
  GraphsActionTypes,
  SET_GRAPHS,
  SET_GRAPHS_NODE_POSITION,
  SET_GROUP_ATTRS,
  SET_GROUP_ATTRS_VALUE,
  UPDATE_GROUP_ATTRS,
  UPDATE_GRAPHS,
  SET_GROUP_ATTRS_PRIORITY,
  ADD_NODE,
  UPDATE_NODE,
  REMOVE_NODE,
  ADD_EDGE,
  UPDATE_EDGE,
  IEdge,
  INode,
  REMOVE_EDGE,
} from "./types";

const initialState: IGraphs = {
  edges: [],
  nodes: [],
  group_attrs: [],
};
export function graphsReducer(state = initialState, action: GraphsActionTypes): IGraphs {
  switch (action.type) {
    case SET_GRAPHS:
      return action.payload;
    case UPDATE_GRAPHS:
      return { ...state, nodes: action.payload.nodes, edges: action.payload.edges };
    case SET_GRAPHS_NODE_POSITION:
      return {
        ...state,
        nodes: state.nodes.map((item: any) => {
          if (item.id === action.payload.id) {
            return { ...item, ...action.payload };
          } else {
            return item;
          }
        }),
      };
    case ADD_NODE:
      return {
        ...state,
        nodes: [...state.nodes, action.payload],
      };
    case UPDATE_NODE:
      return {
        ...state,
        nodes: state.nodes.map((item: INode) => {
          if (item.id === action.payload.id) {
            return { ...item, ...action.payload };
          } else {
            return item;
          }
        }),
      };
    case REMOVE_NODE:
      return {
        ...state,
        nodes: state.nodes.filter((n) => n.id !== action.payload),
      };
    case ADD_EDGE:
      return {
        ...state,
        edges: [...state.edges, action.payload],
      };
    case UPDATE_EDGE:
      return {
        ...state,
        edges: state.edges.map((item: IEdge) => {
          if (item.id === action.payload.id) {
            return { ...item, ...action.payload };
          } else {
            return item;
          }
        }),
      };
    case REMOVE_EDGE:
      return {
        ...state,
        edges: state.edges.filter((n) => n.id !== action.payload),
      };
    case SET_GROUP_ATTRS:
      return { ...state, group_attrs: action.payload };
    case SET_GROUP_ATTRS_VALUE:
      return {
        ...state,
        group_attrs: state.group_attrs.map((item) => {
          return item.name === action.payload.name
            ? { ...item, value: action.payload.value }
            : item;
        }),
      };
    case SET_GROUP_ATTRS_PRIORITY:
      return {
        ...state,
        group_attrs: state.group_attrs.map((item) => {
          return item.id === action.payload.id
            ? { ...item, priority: action.payload.priority }
            : item;
        }),
      };
    case UPDATE_GROUP_ATTRS:
      return {
        ...state,
        group_attrs: state.group_attrs.map((item) => {
          return item.id === action.payload.id
            ? { ...item, [action.payload.key]: action.payload.value }
            : item;
        }),
      };
    default:
      return state;
  }
}
