import { AppThunk } from "..";
import { asyncLoadNodeClasses } from "../../services/group_attrs.service";
import { setLoading } from "../app/actions";
import {
  SET_NODECLASSES,
  ADD_NODECLASSES,
  REMOVE_NODECLASSES,
  UPDATE_NODECLASSES,
  NodeClassActionTypes,
  INodeClass,
} from "./types";

export function setNodeClasses(presets: INodeClass[]): NodeClassActionTypes {
  return {
    type: SET_NODECLASSES,
    payload: presets,
  };
}

export function addNodeClasses(preset: INodeClass): NodeClassActionTypes {
  return {
    type: ADD_NODECLASSES,
    payload: preset,
  };
}

export function updateNodeClasses(preset: INodeClass): NodeClassActionTypes {
  return {
    type: UPDATE_NODECLASSES,
    payload: preset,
  };
}

export function removeNodeClasses(preset_id: string): NodeClassActionTypes {
  return {
    type: REMOVE_NODECLASSES,
    payload: preset_id,
  };
}

export const getNodeClasses = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const node_classes = await asyncLoadNodeClasses();
    node_classes && dispatch(setNodeClasses(node_classes));
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};
