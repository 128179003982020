import React from "react";
import { Select } from "antd";
import { ControlProps } from "./ControlProps";

interface SelectControlProps extends ControlProps {
  options: { label: string; value: string }[];
}

const SelectControl: React.FC<SelectControlProps> = ({ label, value, onChange, options }) => {
  return (
    <div className="filter__item">
      {label} <br />
      <Select style={{ width: "100%" }} value={value} options={options} onChange={onChange} />
    </div>
  );
};

export default SelectControl;
