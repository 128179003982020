import axios from "axios";
import { IScenario } from "../store/scenarios/types";

export const asyncLoadScenariosByOntoId = async (
  onto_id: string
): Promise<IScenario[] | undefined> => {
  try {
    const res = await axios.get(`/ontologies/${onto_id}/scenarios`);
    return res.data;
  } catch (error) {}
};
