export enum ModelType {
  "nodes",
  "edges",
}
export interface IComment {
  id: string;
  text: string;
  author_id: string;
  model_item_id: string;
  update_time: string;
  item_title?: string;
}

export const SET_COMMENTS = "COMMENTS/SET";
export const ADD_COMMENTS = "COMMENTS/ADD";
export const REMOVE_COMMENTS = "COMMENTS/REMOVE";
export const UPDATE_COMMENTS = "COMMENTS/UPDATE";

interface ICommentSetAction {
  type: typeof SET_COMMENTS;
  payload: IComment[];
}

interface ICommentUpdateAction {
  type: typeof UPDATE_COMMENTS;
  payload: IComment;
}

interface ICommentAddAction {
  type: typeof ADD_COMMENTS;
  payload: IComment;
}

interface ICommentRemoveAction {
  type: typeof REMOVE_COMMENTS;
  payload: string;
}

export type CommentsActionTypes =
  | ICommentSetAction
  | ICommentUpdateAction
  | ICommentAddAction
  | ICommentRemoveAction;
