import { ModelId } from "../ontologies/types";

export type NodeClassId = string & { readonly __tag: unique symbol };

export interface INodeClass {
  id: NodeClassId;
  name: string;
  onto_id: ModelId;
  descr: string;
}

export const SET_NODECLASSES = "NODECLASSES/SET";
export const ADD_NODECLASSES = "NODECLASSES/ADD";
export const REMOVE_NODECLASSES = "NODECLASSES/REMOVE";
export const UPDATE_NODECLASSES = "NODECLASSES/UPDATE";

interface INodeClassSetAction {
  type: typeof SET_NODECLASSES;
  payload: INodeClass[];
}

interface INodeClassUpdateAction {
  type: typeof UPDATE_NODECLASSES;
  payload: INodeClass;
}

interface INodeClassAddAction {
  type: typeof ADD_NODECLASSES;
  payload: INodeClass;
}

interface INodeClassRemoveAction {
  type: typeof REMOVE_NODECLASSES;
  payload: string;
}

export type NodeClassActionTypes =
  | INodeClassSetAction
  | INodeClassUpdateAction
  | INodeClassAddAction
  | INodeClassRemoveAction;
