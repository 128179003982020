export const qsOntoParams = (
  nodes?: string[] | string,
  hide?: string[] | string,
  isStart: boolean = false
): string => {
  let nodes_str = isStart ? "?" : "",
    hide_str = isStart ? "?" : "";
  if (nodes) {
    if (typeof nodes === "string") {
      nodes_str = `&nodes=${nodes}`;
    } else {
      nodes_str = nodes.reduce((url, param) => (url += `&nodes=${param}`), "");
    }
  }
  if (hide) {
    if (typeof hide === "string") {
      hide_str = `&hide=${hide}`;
    } else {
      hide_str = hide.reduce((url, param) => (url += `&hide=${param}`), "");
    }
  }
  return nodes_str + hide_str;
};
