import { UserState, AuthActionTypes, SET_LOG_IN, SET_LOG_OUT } from "./types";

const initialState: UserState = {
  token: localStorage.getItem("sso_token_leader_id"),
  isLoggedIn: false,
  id: "",
  username: "",
  firstname: "",
  lastname: "",
  email: "",
  photo_urls: {
    Large: "",
    Medium: "",
    Original: "",
    Small: "",
  },
  leader_id: "",
  role: "",
};

export function authReducer(state = initialState, action: AuthActionTypes): UserState {
  switch (action.type) {
    case SET_LOG_IN:
      return { ...state, ...action.payload, isLoggedIn: true };
    case SET_LOG_OUT:
      return { ...state, isLoggedIn: false };
    default:
      return state;
  }
}
