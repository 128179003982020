import { useState } from "react";

export const useUniqueArray = <T>() => {
  const [state, setState] = useState<T[]>([]);

  const add = (item: T) => {
    const arr = new Set(state);
    arr.add(item);
    setState(Array.from(arr));
  };

  const remove = (item: T) => {
    const arr = new Set(state);
    arr.delete(item);
    setState(Array.from(arr));
  };

  const has = (item: T): boolean => {
    const arr = new Set(state);
    return arr.has(item);
  };

  return [state, add, remove, has] as const;
};
