import { AppThunk } from "..";
import { asyncLoadViews } from "../../services/views.service";
import { setLoading } from "../app/actions";
import { SET_VIEWS, ADD_VIEWS, REMOVE_VIEWS, UPDATE_VIEWS, ViewsActionTypes, IView } from "./types";

export function setViews(views: IView[]): ViewsActionTypes {
  return {
    type: SET_VIEWS,
    payload: views,
  };
}

export function addView(view: IView): ViewsActionTypes {
  return {
    type: ADD_VIEWS,
    payload: view,
  };
}

export function updateView(view: IView): ViewsActionTypes {
  return {
    type: UPDATE_VIEWS,
    payload: view,
  };
}

export function removeView(view_id: string): ViewsActionTypes {
  return {
    type: REMOVE_VIEWS,
    payload: view_id,
  };
}

export const getViews = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const views = await asyncLoadViews();
    views && dispatch(setViews(views));
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};
