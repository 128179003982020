import { AppThunk } from "..";
import { asyncLoadEditors } from "../../services/editors.service";
import { setLoading } from "../app/actions";
import {
  SET_EDITORS,
  ADD_EDITORS,
  REMOVE_EDITORS,
  UPDATE_EDITORS,
  EditorsActionTypes,
  IEditor,
} from "./types";

export function setEditors(users: IEditor[]): EditorsActionTypes {
  return {
    type: SET_EDITORS,
    payload: users,
  };
}

export function addEditors(users: IEditor[]): EditorsActionTypes {
  return {
    type: ADD_EDITORS,
    payload: users,
  };
}

export function updateEditors(user: IEditor): EditorsActionTypes {
  return {
    type: UPDATE_EDITORS,
    payload: user,
  };
}

export function removeEditors(user_id: string): EditorsActionTypes {
  return {
    type: REMOVE_EDITORS,
    payload: user_id,
  };
}

export const getEditors = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const users = await asyncLoadEditors();
    users && dispatch(setEditors(users));
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};
