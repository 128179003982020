export interface UserState {
  id: string;
  leader_id: string;
  username: string;
  role: string;
  email: string;
  firstname: string;
  lastname: string;
  photo_urls: {
    Small: string;
    Medium: string;
    Large: string;
    Original: string;
  };
  isLoggedIn: boolean;
  token: string | null;
}

export const SET_LOG_IN = "AUTH/LOGIN";
export const SET_LOG_OUT = "AUTH/LOGOUT";
export const WHO_AM_I = "AUTH/WHOAMI";
export const TOGGLE_CHECK = "AUTH/TOGGLE_CHECK";

interface IToggleCheck {
  type: typeof TOGGLE_CHECK;
}

interface ILogInAction {
  type: typeof SET_LOG_IN;
  payload: UserState;
}

interface ILogOutAction {
  type: typeof SET_LOG_OUT;
}

interface IWhoAmIAction {
  type: typeof WHO_AM_I;
}

export type AuthActionTypes = ILogInAction | ILogOutAction | IWhoAmIAction | IToggleCheck;
