import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { INode } from "../../store/graphs/types";
import { IView } from "../../store/views/types";
import { IScenario } from "../../store/scenarios/types";

type ScenarioModalProps = {
  view?: IView;
  scenario?: IScenario;
  onSelectScenario: (scenario: string) => void;
  onUnselectScenario: (scenario: string) => void;
};

const ScenarioModal: React.FC<ScenarioModalProps> = ({
  view,
  scenario,
  onSelectScenario,
  onUnselectScenario,
}) => {
  const [checkboxes, setCheckboxes] = useState<INode[]>([]);

  const handleSelect = (e: CheckboxChangeEvent) => {
    const { value, checked } = e.target;
    if (checked) {
      onSelectScenario(value);
    } else {
      onUnselectScenario(value);
    }
  };

  /**
   * Функция асинхронного получения нодов класса
   */
  const getNodes = useCallback(async () => {
    if (!scenario) return;
    try {
      const req = await axios(`/views/${view?.id}/nodes?nodeclass=${scenario.params.nodeclass_id}`);
      setCheckboxes(req.data);
    } catch (error) {}
  }, [scenario, view]);

  /**
   * Получаем список параметров класса
   */
  useEffect(() => {
    getNodes();
  }, [getNodes]);

  if (!view || !scenario) return <p>Вы не выбрали вид или сценарий</p>;
  return (
    <>
      {checkboxes.map((item) => (
        <Checkbox key={item.id} value={item.id} onChange={handleSelect}>
          {item.label}
        </Checkbox>
      ))}
    </>
  );
};

export default React.memo(ScenarioModal);
