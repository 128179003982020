import axios from "axios";
import { IdType } from "vis-network";
import { IComment } from "../store/comments/types";
import { IGraphs } from "../store/graphs/types";

export const asyncLoadComments = async (onto_id: string): Promise<IComment[]> => {
  try {
    const res = await axios(`/ontologies/${onto_id}/comments`);
    return res.data;
  } catch (error) {
    return [];
  }
};
export const asyncRemoveComment = async (id: string): Promise<Boolean> => {
  const res = await axios.delete(`/comments/${id}`);
  return res.status === 204;
};

export const asyncPostComment = async (
  itemId: IdType,
  type: keyof IGraphs,
  values: any
): Promise<IComment | null> => {
  try {
    const res = await axios.post(`/${type}/${itemId}/comments`, values);
    return res.data;
  } catch (error) {
    return null;
  }
};
