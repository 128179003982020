import React from "react";
import { Slider } from "antd";
import { ControlProps } from "./ControlProps";

interface SliderControlProps extends ControlProps {
  min: number;
  max: number;
  step: number;
}

const SliderControl: React.FC<SliderControlProps> = ({
  label,
  value,
  onChange,
  min,
  max,
  step,
}) => {
  return (
    <div className="filter__item">
      {label}
      <Slider min={min} max={max} onChange={onChange} value={value} step={step} />
    </div>
  );
};

export default SliderControl;
