import { Edge, Node } from "vis-network/standalone/esm/vis-network";
import { NodeClassId } from "../nodeclasses/types";
import { ModelId } from "../ontologies/types";
import { ViewId } from "../views/types";

export interface INodeGroupParams {
  name: string;
  value: string;
  visualname: string;
}

export interface INode extends Node {
  onto_id: ModelId;
  node_class_id: NodeClassId;
  model_node_id: string;
  nodesets?: string[];
  checked?: boolean;
  isNew?: boolean;
  group_params?: INodeGroupParams[];
}

export interface IEdge extends Edge {
  model_edge_id: string;
  isNew?: boolean;
}

export type IGroupAttrsValue = {
  value: string;
  preset: string;
};

export interface IGroupAttrs {
  id: string;
  view_id: ViewId;
  name: string;
  priority: number;
  visual_name: string;
  values: IGroupAttrsValue[];
  default_value: string;
  value?: string;
}
export interface IGraphs {
  nodes: INode[];
  edges: IEdge[];
  group_attrs: IGroupAttrs[];
  params?: string;
}

export const SET_GRAPHS = "GRAPHS/SET";
export const UPDATE_GRAPHS = "GRAPHS/UPDATE";
export const REMOVE_GRAPHS = "GRAPHS/REMOVE";
export const ADD_NODE = "NODE/ADD";
export const UPDATE_NODE = "NODE/UPDATE";
export const REMOVE_NODE = "NODE/REMOVE";
export const ADD_EDGE = "EDGE/ADD";
export const UPDATE_EDGE = "EDGE/UPDATE";
export const REMOVE_EDGE = "EDGE/REMOVE";
export const SET_GRAPHS_NODE_POSITION = "GRAPHS/SET_NODE_POSITION";
export const SET_GROUP_ATTRS = "GROUP_ATTRS/SET";
export const SET_GROUP_ATTRS_VALUE = "GROUP_ATTRS/SET_VALUE";
export const SET_GROUP_ATTRS_PRIORITY = "GROUP_ATTRS/SET_PRIORITY";
export const UPDATE_GROUP_ATTRS = "GROUP_ATTRS/UPDATE";

interface INodeAddAction {
  type: typeof ADD_NODE;
  payload: INode;
}

interface INodeUpdateAction {
  type: typeof UPDATE_NODE;
  payload: INode;
}

interface INodeRemoveAction {
  type: typeof REMOVE_NODE;
  payload: string;
}

interface IEdgeAddAction {
  type: typeof ADD_EDGE;
  payload: IEdge;
}

interface IEdgeUpdateAction {
  type: typeof UPDATE_EDGE;
  payload: IEdge;
}

interface IEdgeRemoveAction {
  type: typeof REMOVE_EDGE;
  payload: string;
}

interface IGroupAttrsUpdateAction {
  type: typeof UPDATE_GROUP_ATTRS;
  payload: { id: string; key: string; value: string };
}

interface IGroupAttrsSetAction {
  type: typeof SET_GROUP_ATTRS;
  payload: IGroupAttrs[];
}

interface IGroupAttrsValueSetAction {
  type: typeof SET_GROUP_ATTRS_VALUE;
  payload: { name: string; value: string };
}

interface IGroupAttrsPrioritySetAction {
  type: typeof SET_GROUP_ATTRS_PRIORITY;
  payload: { id: string; priority: number };
}

interface IGraphsSetAction {
  type: typeof SET_GRAPHS;
  payload: IGraphs;
}

interface IGraphsUpdateAction {
  type: typeof UPDATE_GRAPHS;
  payload: {
    nodes: INode[];
    edges: IEdge[];
  };
}

interface IGraphsNodePositionSetAction {
  type: typeof SET_GRAPHS_NODE_POSITION;
  payload: { id: string; x: number; y: number };
}

export type GraphsActionTypes =
  | IGraphsSetAction
  | IGraphsNodePositionSetAction
  | IGroupAttrsSetAction
  | IGroupAttrsUpdateAction
  | IGroupAttrsValueSetAction
  | IGroupAttrsPrioritySetAction
  | INodeAddAction
  | INodeUpdateAction
  | INodeRemoveAction
  | IEdgeUpdateAction
  | IEdgeRemoveAction
  | IEdgeAddAction
  | IGraphsUpdateAction;

export const isNode = (item?: INode | IEdge): item is INode => {
  return item ? (item as INode).model_node_id !== undefined : false;
};
