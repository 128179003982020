import axios from "axios";
import { AppThunk } from "..";
import { setLoading } from "../app/actions";
import { getEditors } from "../editors/actions";
import { getNodeClasses } from "../nodeclasses/actions";
import { getViews } from "../views/actions";
import { asyncLoadPresets } from "../presets/actions";
import { asyncLoadPublications } from "../publications/actions";
import { asyncLoadScenarios } from "../scenarios/actions";

import {
  SET_ONTOLOGIES,
  ADD_ONTOLOGIES,
  REMOVE_ONTOLOGIES,
  OntologiesActionTypes,
  IOntology,
} from "./types";

export function setOntologies(ontologies: IOntology[]): OntologiesActionTypes {
  return {
    type: SET_ONTOLOGIES,
    payload: ontologies,
  };
}

export function addOntologies(ontologies: IOntology): OntologiesActionTypes {
  return {
    type: ADD_ONTOLOGIES,
    payload: ontologies,
  };
}

export function removeOntologies(ontology_id: string): OntologiesActionTypes {
  return {
    type: REMOVE_ONTOLOGIES,
    payload: ontology_id,
  };
}

export const asyncLoadOntologies = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const req = await axios(`/ontologies`);
    if (req.status === 200 && req.data.length > 0) {
      dispatch(setOntologies(req.data));
      dispatch(getNodeClasses());
      dispatch(getEditors());
      dispatch(getViews());
      dispatch(asyncLoadPresets());
      dispatch(asyncLoadScenarios());
      dispatch(asyncLoadPublications());
    }
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};
