import axios from "axios";
import { AppThunk } from "..";
import { setLoading } from "../app/actions";
import {
  SET_PRESETS,
  ADD_PRESETS,
  REMOVE_PRESETS,
  PresetsActionTypes,
  IPresets,
  UPDATE_PRESETS,
} from "./types";

export function setPresets(presets: IPresets[]): PresetsActionTypes {
  return {
    type: SET_PRESETS,
    payload: presets,
  };
}

export function addPresets(preset: IPresets): PresetsActionTypes {
  return {
    type: ADD_PRESETS,
    payload: preset,
  };
}

export function updatePresets(preset: IPresets): PresetsActionTypes {
  return {
    type: UPDATE_PRESETS,
    payload: preset,
  };
}

export function removePresets(preset_id: string): PresetsActionTypes {
  return {
    type: REMOVE_PRESETS,
    payload: preset_id,
  };
}

export const asyncLoadPresets = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const req = await axios(`/presets`);
    if (req.status === 200) {
      dispatch(setPresets(req.data));
    }
  } catch (error) {
    //dispatch(setUuid(""));
  } finally {
    dispatch(setLoading(false));
  }
};
