import React from "react";
import { ConfigProps } from "./ConfigProps";
import ColorControl from "./controls/ColorControl";
import SelectControl from "./controls/SelectControl";
import SliderControl from "./controls/SliderControl";
import SwitchControl from "./controls/SwitchControl";

const NodesConfig: React.FC<ConfigProps> = ({ options, setOptions }) => {
  const shapes = [
    { value: "box", label: "Контейнер" },
    { value: "square", label: "Квадрат" },
    { value: "ellipse", label: "Окружность" },
    { value: "dot", label: "Точка" },
    { value: "circle", label: "Круг" },
    { value: "diamond", label: "Ромб" },
    { value: "triangle", label: "Треугольник" },
    { value: "triangleDown", label: "Треугольник вниз" },
    { value: "star", label: "Звезда" },
    { value: "database", label: "База данных" },
  ];

  const widthConstraint = options.nodes?.widthConstraint;

  return (
    <>
      <SelectControl
        label="Форма элемента"
        value={options.nodes?.shape}
        options={shapes}
        onChange={(value) => {
          setOptions({ ...options, nodes: { ...options.nodes, shape: value } });
        }}
      />
      <SliderControl
        label="Размер фигуры"
        onChange={(e: number) => setOptions({ ...options, nodes: { ...options.nodes, size: e } })}
        value={options.nodes?.size}
        step={1}
        min={5}
        max={100}
      />
      <SliderControl
        label="Максимальная ширина подписи"
        onChange={(e: number) =>
          setOptions({
            ...options,
            nodes: {
              ...options.nodes,
              widthConstraint: {
                maximum: e,
              },
            },
          })
        }
        value={
          typeof widthConstraint !== "number" && typeof widthConstraint !== "boolean"
            ? widthConstraint?.maximum
            : widthConstraint
        }
        step={10}
        min={100}
        max={1000}
      />
      <ColorControl
        label="Цвет фона элемента"
        value={options.nodes?.color?.toString()}
        onChange={(color) => {
          setOptions({ ...options, nodes: { ...options.nodes, color } });
        }}
      />
      <SwitchControl
        label="Тень объектов"
        value={!!options.nodes?.shadow}
        onChange={(shadow) => setOptions({ ...options, nodes: { ...options.nodes, shadow } })}
      />
      <SliderControl
        label="Прозрачность объектов"
        value={options.nodes?.opacity}
        onChange={(opacity: number) =>
          setOptions({ ...options, nodes: { ...options.nodes, opacity } })
        }
        step={0.01}
        min={0}
        max={1}
      />
    </>
  );
};

export default React.memo(NodesConfig);
