import { Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { IView } from "../../store/views/types";
import { IScenario } from "../../store/scenarios/types";

type ScenariosProps = {
  scenarios: IScenario[];
  view: IView;
  onOpen: (scenario: IScenario, view: IView) => void;
};

const Scenarios: React.FC<ScenariosProps> = ({ scenarios, view, onOpen }) => {
  return (
    <Menu>
      <Menu.Item key="view">
        <Link to={`/view/${view.id}`}>Посмотреть вид полностью</Link>
      </Menu.Item>
      {scenarios.map((scenario) => (
        <Menu.Item key={scenario.id} onClick={() => onOpen(scenario, view)}>
          {scenario.name}
        </Menu.Item>
      ))}
    </Menu>
  );
};

export default Scenarios;
