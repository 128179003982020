import axios from "axios";
import { Options } from "vis-network/standalone/esm/vis-network";
import { message } from "antd";
import { IPresets } from "../store/presets/types";

export const asyncLoadPresetById = async (id: string): Promise<IPresets | undefined> => {
  try {
    const res = await axios.get(`/presets/${id}`);
    return res.data;
  } catch (error) {}
};

export const addPreset = async (onto_id: string, params: any) => {
  try {
    const req = await axios.post(`/presets/onto/${onto_id}`, { ...params, content: {} });
    return req.data;
  } catch (error) {}
};

export const savePreset = (id: string, options: Options) => {
  try {
    axios.put(`/presets/${id}`, { content: options });
    message.success("Пресет успешно сохранен!");
  } catch (error) {}
};

export const removePreset = async (id: string) => {
  try {
    const req = await axios.delete(`/presets/${id}`);
    return req.status;
  } catch (error) {}
};
