import React, { useCallback, useEffect } from "react";
import axios from "axios";
import { message } from "antd";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setLoading } from "../store/app/actions";
import { setLogIn } from "../store/auth/actions";

const SSORedirect = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const searchParams = Object.fromEntries(new URLSearchParams(location.search));

  /**
   * Проверяем код авторизации
   */
  const checkCode = useCallback(
    async (code: string) => {
      try {
        // Включаем загрузку
        dispatch(setLoading(true));
        // Отправляем запрос на сервер
        const req = await axios(`/sso_redirect?code=${code}`);
        // Записываем данные в хранилище
        dispatch(setLogIn({ ...req.data.user, token: req.data.token }));
        // Записываем токен в браузер
        localStorage.setItem("sso_token_leader_id", req.data.token);
        // Уведомленяем
        message.success("Вы успешно авторизованы!");
        // Перенаправляем
        history.push("/");
      } catch (error) {
        message.warning("Ошибка авторизации");
        history.push("/");
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, history]
  );

  /**
   * Проверяем наличие кода в строке урл и запускам проверку на сервере
   * или ругаемся!
   */
  useEffect(() => {
    if (searchParams?.code) {
      checkCode(searchParams.code.toString());
    } else {
      message.warning("Отсутствует код авторизации");
      history.push("/");
    }
  }, [checkCode, searchParams?.code, history]);

  return <></>;
};

export default SSORedirect;
