import axios from "axios";
import { IPublication } from "../store/publications/types";
import { IView } from "../store/views/types";

export const saveView = async (id: string, name: string, value: any) => {
  try {
    const req = await axios.put(`/views/${id}`, { [name]: value });
    return req.data;
  } catch (error) {}
};

export const newView = async (onto_id: string, options: any) => {
  try {
    const req = await axios.post(`/fitview/${onto_id}`, options);
    return req.data;
  } catch (error) {}
};

export const newPublication = async (options: any) => {
  try {
    const req = await axios.post(`/publications`, options);
    return req.data;
  } catch (error) {}
};

export const savePublication = async (id: string, name: string, value: any) => {
  try {
    const req = await axios.put(`/publications/${id}`, { [name]: value });
    return req.data;
  } catch (error) {}
};

export const asyncLoadViewById = async (id: string): Promise<IPublication | undefined> => {
  try {
    const res = await axios.get(`/views/${id}`);
    return res.data;
  } catch (error) {}
};

export const asyncLoadViews = async (): Promise<IView[] | undefined> => {
  try {
    const res = await axios.get(`/views`);
    return res.data;
  } catch (error) {}
};
