import { Button, Result } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { AUTH_URL } from "../helpers/constants";
import { RootState } from "../store";

const Login = () => {
  const { isLoggedIn } = useSelector((state: RootState) => state.auth);

  if (isLoggedIn) return <Redirect to="/ontologies" />;

  return (
    <Result
      status="403"
      title="У вас нет доступа к этой странице"
      subTitle="Войдите в систему с помощью Leader ID"
      extra={
        process.env.NODE_ENV !== "development" ? (
          <a rel="noopener noreferrer" href={AUTH_URL}>
            <Button type="primary">Войти через Leader ID</Button>
          </a>
        ) : (
          <Link to="/ontologies">
            <Button type="primary">Войти через Leader ID</Button>
          </Link>
        )
      }
    />
  );
};

export default Login;
