import { Alert, Button, Form, Select } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { setGroupAttrValue } from "../store/graphs/actions";
import { IGroupAttrs } from "../store/graphs/types";
import { IView } from "../store/views/types";

type FilterProps = {
  group_attrs: IGroupAttrs[];
  view?: IView;
};

const Filter: React.FC<FilterProps> = ({ view, group_attrs }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  /**
   * Обновляем значение групповых атрибутов
   * @param changedValue измененные значения формы
   */
  const handleChangeFilter = (changedValue: any) => {
    const values = Object.entries<string>(changedValue);
    values.forEach(([key, value]) => {
      dispatch(setGroupAttrValue(key, value));
    });
  };

  /**
   * Сбрасываем значения формы фильтрации
   */
  const handleResetFilter = () => {
    group_attrs.forEach((item) => {
      dispatch(setGroupAttrValue(item.name, ""));
    });
    form.resetFields(group_attrs.map((attr) => attr.name));
  };

  if (group_attrs.length === 0)
    return <Alert message="В онтологии нет групповых атрибутов" type="info" />;

  return (
    <Form form={form} onValuesChange={handleChangeFilter} layout="vertical">
      {group_attrs.map((ga, key) => (
        <Form.Item key={key} name={ga.name} label={ga.visual_name} className="filter__item">
          <Select
            value={ga.value || ga.default_value}
            style={{ width: "100%" }}
            options={
              ga.values
                ? ga.values.map((attr) => {
                    return {
                      label: attr.value,
                      value: attr.value,
                    };
                  })
                : []
            }
          />
        </Form.Item>
      ))}
      <Form.Item>
        <Button type="default" block onClick={handleResetFilter}>
          Сбросить фильтр
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Filter;
