import React from "react";
import { Options } from "vis-network/declarations/entry-standalone";
import VisNetwork from "../components/VisNetwork";

const Demo = () => {
  const nodes = [
    { id: 0, label: "Label 0" },
    { id: 1, label: "Label 1" },
    { id: 2, label: "Label 2" },
    { id: 3, label: "Label 3" },
    { id: 4, label: "Label 4" },
    { id: 5, label: "Label 5" },
    { id: 6, label: "Label 6" },
    { id: 7, label: "Label 7" },
    { id: 8, label: "Label 8" },
    { id: 9, label: "Label 9" },
    { id: 10, label: "Label 10 Label 10 Label 10 Label 10 Label 10" },
    { id: 11, label: "Label 11" },
    { id: 12, label: "Label 12" },
    { id: 13, label: "Label 13" },
    { id: 14, label: "Label 14" },
    { id: 15, label: "Label 15" },
    { id: 16, label: "Label 16 Label 16 Label 16 Label 16" },
    { id: 17, label: "Label 17" },
    { id: 18, label: "Label 18" },
    { id: 19, label: "Label 19" },
    { id: 20, label: "Label 20" },
    { id: 21, label: "Label 21" },
    { id: 22, label: "Label 22" },
    { id: 23, label: "Label 23" },
    { id: 24, label: "Label 24" },
    { id: 25, label: "Label 25 Label 25 Label 25 Label 25" },
    { id: 26, label: "Label 26" },
    { id: 27, label: "Label 27" },
    { id: 28, label: "Label 28" },
    { id: 29, label: "Label 29" },
    { id: 30, label: "Label 30" },
    { id: 31, label: "Label 31" },
    { id: 32, label: "Label 32" },
    { id: 33, label: "Label 33" },
    { id: 34, label: "Label 34" },
    { id: 35, label: "Label 35 Label 35 Label 35" },
    { id: 36, label: "Label 36" },
    { id: 37, label: "Label 37" },
    { id: 38, label: "Label 38" },
    { id: 39, label: "Label 39" },
    { id: 40, label: "Label 40" },
    { id: 41, label: "Label 41" },
    { id: 42, label: "Label 42" },
    { id: 43, label: "Label 43" },
    { id: 44, label: "Label 44" },
    { id: 45, label: "Label 45 Label 45" },
    { id: 46, label: "Label 46" },
  ];

  // create an array with edges
  const edges = [
    { from: 0, to: 1 },
    { from: 0, to: 2 },
    { from: 1, to: 3 },
    { from: 1, to: 4 },
    { from: 2, to: 5 },
    { from: 2, to: 6 },
    { from: 3, to: 7 },
    { from: 3, to: 8 },
    { from: 4, to: 9 },
    { from: 4, to: 10 },
    { from: 5, to: 11 },
    { from: 5, to: 12 },
    { from: 6, to: 13 },
    { from: 6, to: 14 },
    { from: 7, to: 15 },
    { from: 7, to: 16 },
    { from: 8, to: 17 },
    { from: 8, to: 18 },
    { from: 9, to: 19 },
    { from: 9, to: 20 },
    { from: 10, to: 21 },
    { from: 10, to: 22 },
    { from: 11, to: 23 },
    { from: 11, to: 24 },
    { from: 12, to: 25 },
    { from: 12, to: 26 },
    { from: 13, to: 27 },
    { from: 13, to: 28 },
    { from: 14, to: 29 },
    { from: 14, to: 30 },
    { from: 15, to: 31 },
    { from: 16, to: 32 },
    { from: 17, to: 33 },
    { from: 18, to: 34 },
    { from: 19, to: 35 },
    { from: 20, to: 36 },
    { from: 21, to: 37 },
    { from: 22, to: 38 },
    { from: 23, to: 39 },
    { from: 24, to: 40 },
    { from: 25, to: 41 },
    { from: 26, to: 42 },
    { from: 27, to: 43 },
    { from: 28, to: 44 },
    { from: 29, to: 45 },
    { from: 30, to: 46 },
  ];

  const options: Options = {
    autoResize: true,
    height: "100%",
    width: "100%",
    locale: "ru",
    manipulation: {
      enabled: true,
    },
    layout: {
      randomSeed: 1,
      improvedLayout: true,
      hierarchical: {
        enabled: true,
        direction: "LR",
        sortMethod: "directed",
      },
    },
    physics: {
      enabled: true,
      solver: "repulsion",
      hierarchicalRepulsion: {
        avoidOverlap: 1,
      },
    },
    nodes: {
      shape: "box",
      size: 10,
      shadow: false,
      opacity: 1,
      color: "#97C2FC",
      widthConstraint: {
        maximum: 300,
      },
    },
    edges: {
      arrows: "to",
      dashes: false,
      color: "#97C2FC",
      smooth: {
        enabled: false,
        type: "dynamic",
        roundness: 0,
      },
    },
  };
  return <VisNetwork data={{ nodes, edges }} options={options} />;
};

export default Demo;
