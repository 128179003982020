import {
  ADD_EDITORS,
  REMOVE_EDITORS,
  IEditor,
  EditorsActionTypes,
  SET_EDITORS,
  UPDATE_EDITORS,
} from "./types";

const initialState: IEditor[] = [];
export function editorsReducer(state = initialState, action: EditorsActionTypes): IEditor[] {
  switch (action.type) {
    case SET_EDITORS:
      return action.payload;
    case ADD_EDITORS:
      return [...state, ...action.payload];
    case REMOVE_EDITORS:
      return state.filter((item) => item.id !== action.payload);
    case UPDATE_EDITORS:
      return state.map((item) => (item.id === action.payload.id ? action.payload : item));
    default:
      return state;
  }
}
