import {
  SET_ONTOLOGIES,
  ADD_ONTOLOGIES,
  REMOVE_ONTOLOGIES,
  OntologiesActionTypes,
  IOntology,
} from "./types";

const initialState: IOntology[] = [];

export function ontologiesReducer(
  state = initialState,
  action: OntologiesActionTypes
): IOntology[] {
  switch (action.type) {
    case SET_ONTOLOGIES:
      return action.payload;
    case ADD_ONTOLOGIES:
      return [...state, action.payload];
    case REMOVE_ONTOLOGIES:
      return state.filter((item) => item.id !== action.payload);
    default:
      return state;
  }
}
