import { Options } from "vis-network";
import { ModelId } from "../ontologies/types";

export type PresetId = string & { readonly __tag: unique symbol };
export interface IPresets {
  id: PresetId;
  name: string;
  content: Options;
  onto_id: ModelId;
}
export const SET_PRESETS = "PRESETS/SET";
export const ADD_PRESETS = "PRESETS/ADD";
export const REMOVE_PRESETS = "PRESETS/REMOVE";
export const UPDATE_PRESETS = "PRESETS/UPDATE";

interface IPresetsSetAction {
  type: typeof SET_PRESETS;
  payload: IPresets[];
}

interface IPresetsUpdateAction {
  type: typeof UPDATE_PRESETS;
  payload: IPresets;
}

interface IPresetsAddAction {
  type: typeof ADD_PRESETS;
  payload: IPresets;
}

interface IPresetsRemoveAction {
  type: typeof REMOVE_PRESETS;
  payload: string;
}

export type PresetsActionTypes =
  | IPresetsSetAction
  | IPresetsUpdateAction
  | IPresetsAddAction
  | IPresetsRemoveAction;
