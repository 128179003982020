import { AppThunk } from "..";
import { asyncLoadComments } from "../../services/comments.service";
import { setLoading } from "../app/actions";
import {
  SET_COMMENTS,
  ADD_COMMENTS,
  REMOVE_COMMENTS,
  UPDATE_COMMENTS,
  CommentsActionTypes,
  IComment,
} from "./types";

export function setComments(comments: IComment[]): CommentsActionTypes {
  return {
    type: SET_COMMENTS,
    payload: comments,
  };
}

export function addComments(comment: IComment): CommentsActionTypes {
  return {
    type: ADD_COMMENTS,
    payload: comment,
  };
}

export function updateComments(comment: IComment): CommentsActionTypes {
  return {
    type: UPDATE_COMMENTS,
    payload: comment,
  };
}

export function removeComments(comment_id: string): CommentsActionTypes {
  return {
    type: REMOVE_COMMENTS,
    payload: comment_id,
  };
}

export const getComments = (onto_id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const comments = await asyncLoadComments(onto_id);
    comments && dispatch(setComments(comments));
  } catch (error) {
  } finally {
    dispatch(setLoading(false));
  }
};
