import axios from "axios";
import { message } from "antd";
import { IEdge } from "../store/graphs/types";
import { IdType } from "vis-network";

export const asyncAddEdge = async (view_id: string, edge: IEdge) => {
  try {
    if (edge) {
      const req = await axios.post(`/views/${view_id}/edges`, edge);
      message.success("Связь успешно добавлена!");
      return req.data;
    }
  } catch (error) {}
};

export const asyncUpdateEdge = async (edge: IEdge) => {
  try {
    if (edge) {
      const req = await axios.put(`/edges/${edge.id}`, edge);
      message.success("Связи успешно обновлены!");
      return req.data;
    }
  } catch (error) {}
};

export const asyncRemoveEdge = async (edge_id: string) => {
  try {
    if (edge_id) {
      const req = await axios.delete(`/edges/${edge_id}`);
      message.success("Связь удалена");
      return req.data;
    }
  } catch (error) {}
};

export const asyncLoadEdgeComments = async (edge_id: IdType) => {
  try {
    const res = await axios.get(`/edges/${edge_id}/comments`);
    return res.data;
  } catch (error) {}
};
