import axios from "axios";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Data, DataSet, IdType, Network, Options } from "vis-network/standalone/esm/vis-network";
import { useHistory, useParams } from "react-router-dom";
import { Button, Space } from "antd";

import { EventType } from "../types/EventType";
import { RootState } from "../store";
import { IEdge, INode } from "../store/graphs/types";
import { setGroupAttrs, updateGraphs } from "../store/graphs/actions";
import { useQueryParams } from "../hooks/useQueryParams";
import { qsOntoParams } from "../helpers/qsOntoParams";
import { asyncRemoveNode } from "../services/nodes.service";
import { asyncAddEdge, asyncRemoveEdge } from "../services/edges.service";

import VisibleStatusClass from "../components/VisibleStatusClass";
import VisNetwork from "../components/VisNetwork";
import CommentsManager from "../components/comments/CommentsManager";
import HistoryManager from "../components/history/HistoryManager";
import NodePanel from "../components/NodePanel";
import EdgePanel from "../components/EdgePanel";
import { asyncLoadGroupAttrs } from "../services/group_attrs.service";
import Legend from "../components/legend/Legend";

const Scenario = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { view_id } = useParams<{ view_id: string }>();
  const { queryParams, updateParam, removeParam } = useQueryParams();
  const [isManipulationEnabled, setIsManipulationEnabled] = useState(false);
  const [tempDataNode, setTempDataNode] = useState<INode>();
  const [tempDataEdge, setTempDataEdge] = useState<IEdge>();
  const refNetwork = useRef<Network | null>();
  const refNodes = useRef<DataSet | null>();

  const { nodes, edges, group_attrs } = useSelector((state: RootState) => state.graphs);
  const { views, presets, nodeclasses, comments } = useSelector((state: RootState) => state);

  const view = views.find((view) => view.id === view_id);
  const filteredNodeClasses = nodeclasses.filter((node) => node.onto_id === view?.model_id);

  const getNetwork = useCallback((network: Network) => {
    refNetwork.current = network;
  }, []);

  const getNodes = useCallback((nodes: DataSet) => {
    refNodes.current = nodes;
  }, []);

  /**
   * Фокусируемся на указанной ноде
   */
  const handleFocusNode = useCallback((node_id: IdType) => {
    refNetwork.current?.focus(node_id);
  }, []);

  /**
   * Добавляем ноду
   */
  const handleAddNodeToDOM = useCallback((node: INode) => {
    refNodes.current?.add(node);
  }, []);

  /**
   * Делаем ноду выбранной
   */
  const handleSelectNode = useCallback((node_id: IdType) => {
    refNetwork.current?.selectNodes([node_id]);
  }, []);

  /**
   * Делаем ноду выбранной
   */
  const handleSelectEdge = useCallback((edge_id: IdType) => {
    refNetwork.current?.selectNodes([edge_id]);
  }, []);

  /**
   * Загружаем ноды, формируем строку параметров
   */
  const loadData = useCallback(async () => {
    try {
      // Формируем поисковую строку
      const qs = qsOntoParams(queryParams.nodes, queryParams.hide, false);
      // Получаем данные с сервера
      const graphs = await axios(`/views/${view_id}/nodes?type=${queryParams.type}${qs}`);
      // Записываем данные в хранилище
      dispatch(updateGraphs(graphs.data.nodes, graphs.data.edges));
    } catch (error) {}
  }, [dispatch, queryParams.hide, queryParams.nodes, queryParams.type, view_id]);

  /**
   * Загружаем ноды при старте или изменении зависимостей
   */
  useEffect(() => {
    loadData();
  }, [loadData]);

  /**
   * Подгружаем групповые атрибуты для фильтрации
   */
  useEffect(() => {
    if (!view) return;
    (async () => {
      const group_attrs = await asyncLoadGroupAttrs(view.model_id);
      if (group_attrs) {
        dispatch(setGroupAttrs(group_attrs));
      }
    })();
  }, [dispatch, view]);

  // Указываем дефолтные настройки
  const options = useMemo(() => {
    const defaultOptions: Options = {
      autoResize: true,
      height: "100%",
      width: "100%",
      locale: "ru",
      manipulation: {
        enabled: view?.is_editable && isManipulationEnabled,
        addNode: async (node: INode, callback: any) => {
          setTempDataNode({ ...node, isNew: true });
          callback();
        },
        addEdge: async (edge: IEdge, callback: any) => {
          if (edge.from !== edge.to) {
            asyncAddEdge(view_id, edge);
            callback(edge);
          }
        },
        editNode: async (node: INode, callback: any) => {
          if (!view) return;
          setTempDataNode({
            id: node.id,
            onto_id: view?.model_id,
            label: node.label,
            node_class_id: node.node_class_id,
            model_node_id: node.model_node_id,
          });
          callback(node);
        },
        editEdge: async (edge: IEdge, callback: any) => {
          callback(edge);
        },
        deleteNode: (data: Data, callback: any) => {
          data.nodes.forEach((node_id: string) => {
            asyncRemoveNode(node_id);
          });
          callback(data);
        },
        deleteEdge: (data: Data, callback: any) => {
          data.edges.forEach((edge_id: string) => {
            asyncRemoveEdge(edge_id);
          });
          callback(data);
        },
      },
      layout: {
        randomSeed: 1,
        improvedLayout: true,
        hierarchical: {
          enabled: true,
          direction: "LR",
          sortMethod: "directed",
        },
      },
      physics: {
        enabled: false,
        solver: "barnesHut",
        barnesHut: {
          avoidOverlap: 0.5,
          springLength: 100,
        },
        forceAtlas2Based: {
          avoidOverlap: 0.5,
          springLength: 100,
        },
        repulsion: {
          nodeDistance: 100,
        },
        hierarchicalRepulsion: {
          avoidOverlap: 0.5,
          nodeDistance: 100,
        },
      },
      nodes: {
        shape: "square",
        size: 10,
        shadow: false,
        opacity: 1,
        color: "#97C2FC",
        widthConstraint: 300,
      },
    };
    if (view) {
      // Получаем пресет
      const preset = presets.find((p) => p.id === view.preset_id);
      if (preset?.content) {
        // перезаписываем дефолтные настройки пресетом
        return { ...defaultOptions, ...preset.content };
      } else {
        return defaultOptions;
      }
    }
    return defaultOptions;
  }, [isManipulationEnabled, view_id, presets, view]);

  /**
   * Задаем события для VisNetwork
   */
  const events: EventType = useMemo(() => {
    return {
      select: (params: any) => {
        if (params.nodes.length > 0) {
          // Получаем его идентификатор
          const nodeId = params.nodes[0];
          // Ищем его в массиве
          const node = nodes.find((n) => n.id === nodeId);
          // Записываем во временные данные
          setTempDataNode(node);
        } else {
          // Получаем его идентификатор
          const edgeId = params.edges[0];
          // Ищем его в массиве
          const edge = edges.find((n) => n.id === edgeId);
          // Записываем во временные данные
          setTempDataEdge(edge);
        }
      },
    };
  }, [nodes, edges]);

  /**
   * Обновляем url при изменении параметров
   */
  useEffect(() => {
    const qs = qsOntoParams(queryParams.nodes, queryParams.hide);
    history.replace(`/scenario/${view_id}?type=${queryParams.type}${qs}`);
  }, [queryParams, history, view_id]);

  /**
   * Переключаем видимость классов
   */
  const handleToggleVisible = useCallback(
    (node_id: string) => {
      if (typeof queryParams.hide === "string") {
        if (queryParams.hide === node_id) {
          removeParam("hide");
        } else {
          updateParam("hide", [queryParams.hide, node_id]);
        }
      } else if (Array.isArray(queryParams.hide)) {
        // Если hide объект и содержит ид ноды, удаляем его
        if (queryParams.hide?.includes(node_id)) {
          const newHide = queryParams.hide.filter((h) => h !== node_id);
          updateParam("hide", newHide.length > 1 ? newHide : newHide[0]);
        } else {
          const newHide = queryParams.hide.concat(node_id);
          updateParam("hide", newHide);
        }
      } else {
        updateParam("hide", node_id);
      }
    },
    [updateParam, removeParam, queryParams]
  );

  /**
   * Активируем и центрируем узел или связь выбранную в комментариях
   */
  const handleSelectComment = useCallback(
    (model_item_id: string) => {
      // получаем узел комментария
      const findNodeByModelItemId = nodes.find((node) => node.model_node_id === model_item_id);
      // получаем связь комментариях
      const findEdgeByModelItemId = edges.find((edge) => edge.model_edge_id === model_item_id);
      // Если есть узел, синтезируем со списком старовых узлов
      if (findNodeByModelItemId?.id) {
        handleSelectNode(findNodeByModelItemId.id);
        handleFocusNode(findNodeByModelItemId.id);
      }
      // Если есть связь, фокусируемся на ней
      if (findEdgeByModelItemId?.id) {
        handleSelectEdge(findEdgeByModelItemId.id);
      }
    },
    [nodes, edges, handleSelectNode, handleFocusNode, handleSelectEdge]
  );

  return (
    <>
      <div
        className="scenario"
        style={{ position: isManipulationEnabled ? "relative" : "absolute" }}
      >
        <Space wrap>
          {filteredNodeClasses.map((node) => (
            <VisibleStatusClass
              key={node.id}
              node={node}
              queryParams={queryParams}
              onToggle={handleToggleVisible}
            />
          ))}
          {view?.is_editable && (
            <Button
              type={isManipulationEnabled ? "primary" : "default"}
              onClick={() => setIsManipulationEnabled(!isManipulationEnabled)}
            >
              Редактор
            </Button>
          )}
        </Space>
      </div>
      {view && (
        <VisNetwork
          data={{ nodes, edges }}
          options={options}
          events={events}
          getNetwork={getNetwork}
          getNodes={getNodes}
        />
      )}
      {group_attrs.length > 0 && <Legend group_attrs={group_attrs} presets={presets} />}
      {view && (
        <CommentsManager
          onto_id={view.model_id}
          comments={comments}
          onSelect={handleSelectComment}
        />
      )}
      {view && <HistoryManager view_id={view.id} />}

      {tempDataNode && (
        <NodePanel
          onSelectComment={handleSelectComment}
          onFocusNode={handleFocusNode}
          onAddNode={handleAddNodeToDOM}
          view_id={view_id}
          tempDataNode={tempDataNode}
          setTempDataNode={setTempDataNode}
        />
      )}
      {tempDataEdge && (
        <EdgePanel
          tempDataEdge={tempDataEdge}
          setTempDataEdge={setTempDataEdge}
          onSelectComment={handleSelectComment}
        />
      )}
    </>
  );
};

export default React.memo(Scenario);
