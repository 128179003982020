import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store";
import { setGroupAttrs } from "../store/graphs/actions";
import OntologyBlock from "../components/ontologies/OntologyBlock";

const Ontologies = () => {
  const dispatch = useDispatch();
  const { views, ontologies } = useSelector((state: RootState) => state);

  useEffect(() => {
    dispatch(setGroupAttrs([]));
  }, [dispatch]);

  return (
    <div className="ontologies">
      {ontologies.map((ontology) => (
        <OntologyBlock key={ontology.id} ontology={ontology} views={views} />
      ))}
    </div>
  );
};

export default Ontologies;
