import {
  ADD_PRESETS,
  REMOVE_PRESETS,
  IPresets,
  PresetsActionTypes,
  SET_PRESETS,
  UPDATE_PRESETS,
} from "./types";

const initialState: IPresets[] = [];
export function presetsReducer(state = initialState, action: PresetsActionTypes): IPresets[] {
  switch (action.type) {
    case SET_PRESETS:
      return action.payload;
    case ADD_PRESETS:
      return [...state, action.payload];
    case REMOVE_PRESETS:
      return state.filter((preset) => preset.id !== action.payload);
    case UPDATE_PRESETS:
      return state.map((item) => (item.id === action.payload.id ? action.payload : item));
    default:
      return state;
  }
}
