import { Collapse, Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "../store";
import { addComments } from "../store/comments/actions";
import { IComment } from "../store/comments/types";
import { IEdge } from "../store/graphs/types";

import Comments from "./comments/Comments";
import CommentsForm from "./comments/CommentsForm";

const { Panel } = Collapse;

type EdgePanelProps = {
  tempDataEdge: IEdge;
  onSelectComment: (model_item_id: string) => void;
  setTempDataEdge: (node: IEdge | undefined) => void;
};

const EdgePanel: React.FC<EdgePanelProps> = ({
  tempDataEdge,
  setTempDataEdge,
  onSelectComment,
}) => {
  const dispatch = useDispatch();
  const { comments } = useSelector((state: RootState) => state);

  //Добавляем новый комментарий
  const handleAddComment = (comment: IComment) => dispatch(addComments(comment));

  //Фильтруем комментарии узлов и связей
  const filteredComments = comments.filter((c) => c.model_item_id === tempDataEdge.model_edge_id);

  return (
    <Drawer
      width={400}
      title="Данные выбранного элемента"
      placement="right"
      onClose={() => setTempDataEdge(undefined)}
      visible={!!tempDataEdge}
      mask={false}
    >
      <Collapse defaultActiveKey={["comments"]} ghost>
        <Panel header={`Комментарии (${filteredComments.length || 0})`} key="comments">
          <Comments comments={filteredComments} onSelect={onSelectComment} />
          <CommentsForm type="edges" itemId={tempDataEdge.id!} onAdd={handleAddComment} />
        </Panel>
      </Collapse>
    </Drawer>
  );
};

export default EdgePanel;
