import React, { useEffect } from "react";
import { Form, Input, Select } from "antd";
import { INode } from "../store/graphs/types";
import { INodeClass } from "../store/nodeclasses/types";

type NodeEditorProps = {
  nodeclasses: INodeClass[];
  node?: INode;
  onChange: (node: INode) => void;
};

const NodeEditor: React.FC<NodeEditorProps> = ({ node, onChange, nodeclasses }) => {
  const [form] = Form.useForm();

  // При изменении ноды или поля обновляем данные реактивно
  useEffect(() => {
    form.setFieldsValue({
      ...node,
      node_class_id: node?.node_class_id ? node.node_class_id : undefined,
    });
  }, [node, form]);

  // Отправляем измененные данные родителю
  const handleChangeForm = (_: any, newNode: INode) => onChange({ ...node, ...newNode });

  // Если нода не выбрана выводим сообщение
  if (!node) return <div>Выберите элемент для редактирования</div>;

  return (
    <Form form={form} layout="vertical" initialValues={node} onValuesChange={handleChangeForm}>
      <Form.Item name="label" label="Подпись">
        <Input placeholder="Подпись для элемента" />
      </Form.Item>
      <Form.Item name="node_class_id" label="Класс" shouldUpdate={true}>
        <Select
          options={nodeclasses.map((c) => {
            return {
              label: c.name || c.descr,
              value: c.id,
            };
          })}
        />
      </Form.Item>
      <pre>{JSON.stringify(node, null, 2)}</pre>
    </Form>
  );
};

export default NodeEditor;
