import React from "react";
import { IdType } from "vis-network/standalone/esm/vis-network";
import { INode } from "../store/graphs/types";

type LinkedNodeProps = {
  link: INode;
  onFocus: (id: IdType) => void;
};

const LinkedNode: React.FC<LinkedNodeProps> = ({ link, onFocus }) => {
  return (
    <p className="linked-node" onClick={() => onFocus(link.id!)}>
      {link.label}
    </p>
  );
};

export default LinkedNode;
