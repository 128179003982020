import { BulbOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";
import { IGroupAttrs } from "../../store/graphs/types";
import { IPresets } from "../../store/presets/types";
import LegendGroup from "./LegendGroup";

type LegendProps = {
  group_attrs: IGroupAttrs[];
  presets: IPresets[];
};

const Legend: React.FC<LegendProps> = ({ group_attrs, presets }) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className="legend">
      {isVisible &&
        group_attrs.map((group) => <LegendGroup key={group.id} group={group} presets={presets} />)}
      <div className="legend__button">
        <Button
          shape="round"
          type={isVisible ? "default" : "primary"}
          icon={<BulbOutlined />}
          onClick={() => setIsVisible(!isVisible)}
        >
          Легенда
        </Button>
      </div>
    </div>
  );
};

export default Legend;
