import React, { useEffect, useRef } from "react";
import {
  Network,
  Options,
  DataSet,
  NetworkEvents,
  Data,
} from "vis-network/standalone/esm/vis-network";
import isEqual from "lodash/isEqual";
import differenceWith from "lodash/differenceWith";

import "vis-network/styles/vis-network.css";

const defaultOptions = {
  physics: {
    stabilization: false,
  },
  autoResize: false,
  edges: {
    smooth: false,
    color: "#000000",
    width: 0.5,
    arrows: {
      to: {
        enabled: true,
        scaleFactor: 0.5,
      },
    },
  },
};

type EventType = {
  [K in NetworkEvents as string]: (params: any) => void;
};

type VisNetworkProps = {
  data: Data;
  events?: EventType;
  options?: Options;
  getNetwork?: (ref: Network) => void;
  getNodes?: (ref: DataSet) => void;
};

const VisNetwork: React.FC<VisNetworkProps> = ({
  data = { nodes: [], edges: [] },
  options = defaultOptions,
  events = {},
  getNetwork,
  getNodes,
}) => {
  // A reference to the div rendered by this component
  const container = useRef<HTMLDivElement>(null);

  // A reference to the vis network instance
  const network = useRef<Network | null>(null);

  const nodes = useRef(new DataSet(data.nodes));
  const edges = useRef(new DataSet(data.edges));

  /**
   * Монтируем функции
   */
  useEffect(() => {
    if (!container.current) return;
    network.current = new Network(
      container.current,
      { nodes: nodes.current, edges: edges.current },
      options
    );

    if (getNetwork) {
      getNetwork(network.current);
    }

    if (getNodes) {
      getNodes(nodes.current);
    }

    return () => {
      network.current?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Монтируем опции
   */
  useEffect(() => {
    network.current?.setOptions(options);
  }, [options]);

  /**
   * Монтируем изменения
   */
  useEffect(() => {
    const nodesChange = !isEqual(nodes.current, data.nodes);
    const edgesChange = !isEqual(edges.current, data.edges);

    if (nodesChange) {
      const idIsEqual = (n1: any, n2: any) => n1.id === n2.id;
      const nodesRemoved = differenceWith(nodes.current.get(), data.nodes, idIsEqual);
      const nodesAdded = differenceWith(data.nodes, nodes.current.get(), idIsEqual);
      const nodesChanged = differenceWith(
        differenceWith(data.nodes, nodes.current.get(), isEqual),
        nodesAdded
      );

      nodes.current.remove(nodesRemoved);
      nodes.current.add(nodesAdded);
      nodes.current.update(nodesChanged);
    }

    if (edgesChange) {
      const edgesRemoved = differenceWith(edges.current.get(), data.edges, isEqual);
      const edgesAdded = differenceWith(data.edges, edges.current.get(), isEqual);
      const edgesChanged = differenceWith(
        differenceWith(data.edges, edges.current.get(), isEqual),
        edgesAdded
      );
      edges.current.remove(edgesRemoved);
      edges.current.add(edgesAdded);
      edges.current.update(edgesChanged);
    }
  }, [data.nodes, data.edges]);

  /**
   * Монтируем события
   */
  useEffect(() => {
    for (const eventName of Object.keys(events)) {
      network.current?.on(eventName as NetworkEvents, events[eventName]);
    }

    return () => {
      for (const eventName of Object.keys(events)) {
        network.current?.off(eventName as NetworkEvents, events[eventName]);
      }
    };
  }, [events]);

  return <div className="vis-network" ref={container} />;
};

export default React.memo(VisNetwork);
