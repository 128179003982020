import React from "react";
import { IGroupAttrs } from "../../store/graphs/types";
import { IPresets } from "../../store/presets/types";
import LegendGroupItem from "./LegendGroupItem";

type LegendGroupProps = {
  group: IGroupAttrs;
  presets: IPresets[];
};

const LegendGroup: React.FC<LegendGroupProps> = ({ group, presets }) => {
  return (
    <div className="legend__group">
      {group.values.map((item) => (
        <LegendGroupItem
          key={item.value}
          attr={item}
          preset={presets.find((p) => p.id === item.preset)}
        />
      ))}
    </div>
  );
};

export default LegendGroup;
