import { CommentOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncLoadComments } from "../../services/comments.service";
import { RootState } from "../../store";
import { setComments } from "../../store/comments/actions";
import { IComment } from "../../store/comments/types";
import { isNode } from "../../store/graphs/types";
import Comments from "./Comments";

type CommentsManagerProps = {
  onto_id: string;
  comments: IComment[];
  onSelect: (model_item_id: string) => void;
};
const CommentsManager: React.FC<CommentsManagerProps> = ({ onto_id, comments, onSelect }) => {
  const dispatch = useDispatch();
  const { nodes, edges } = useSelector((state: RootState) => state.graphs);
  const [isVisible, setIsVisible] = useState(false);

  const mergedComments = useMemo(() => {
    return comments.map((comment) => {
      const item = [...nodes, ...edges].find((item) => {
        if (isNode(item)) {
          return item.model_node_id === comment.model_item_id;
        } else {
          return item.model_edge_id === comment.model_item_id;
        }
      });
      return { ...comment, item_title: item?.label };
    });
  }, [nodes, edges, comments]);

  useEffect(() => {
    (async () => {
      const comments = await asyncLoadComments(onto_id);
      comments && dispatch(setComments(comments));
    })();
  }, [dispatch, onto_id]);

  return (
    <>
      <div className="discussion">
        <Button
          type="primary"
          size="large"
          onClick={() => setIsVisible(!isVisible)}
          shape="circle"
          icon={<CommentOutlined style={{ fontSize: "2rem" }} />}
        />
      </div>
      <Drawer
        width={400}
        title="Комментарии"
        placement="right"
        onClose={() => setIsVisible(!isVisible)}
        visible={isVisible}
        mask={false}
      >
        <Comments comments={mergedComments} onSelect={onSelect} />
      </Drawer>
    </>
  );
};

export default React.memo(CommentsManager);
