import React from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import rootReducer from "./store";

import App from "./App";

import "./scss/index.scss";

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));

// Указываем дефолтный урл для запросов
axios.defaults.baseURL = process.env.REACT_APP_BACKEND;

// Перехватываем запросы и добавляем туда токен из хранилища
axios.interceptors.request.use(function (config) {
  const token = store.getState().auth.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
