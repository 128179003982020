import { Button, Divider, Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IOntology } from "../../store/ontologies/types";

type OntologyBlockButtonsProps = {
  ontologies: IOntology[];
  role: string;
  ontology: IOntology;
  onSelectToCopy: () => void;
  onSelectToDelete: () => void;
  presetsCount: number;
  viewsCount: number;
  editorsCount: number;
};

const OntologyBlockButtons: React.FC<OntologyBlockButtonsProps> = ({
  ontologies,
  role,
  ontology,
  onSelectToCopy,
  onSelectToDelete,
  presetsCount,
  viewsCount,
  editorsCount,
}) => {
  const [isShownCompareList, setIsShownCompareList] = useState(false);
  return (
    <div className="title__buttons">
      <Link to={`/views/${ontology.id}`}>
        <Button size="small" type="link">
          Виды ({viewsCount})
        </Button>
      </Link>
      <Link to={`/presets/${ontology.id}`}>
        <Button size="small" type="link">
          Пресеты ({presetsCount})
        </Button>
      </Link>
      {role === "admin" && (
        <Link to={`/editors/${ontology.id}`}>
          <Button size="small" type="link">
            Редакторы ({editorsCount})
          </Button>
        </Link>
      )}
      <Divider type="vertical" />
      <Dropdown
        trigger={["click"]}
        visible={isShownCompareList}
        overlay={
          <Menu>
            {ontologies
              .filter((onto) => onto.id !== ontology.id)
              .map((onto) => (
                <Menu.Item key={onto.id}>
                  <Link to={`/compare/${ontology.id}?with=${onto.id}`}>{onto.name}</Link>
                </Menu.Item>
              ))}
          </Menu>
        }
      >
        <Button size="small" type="link" onClick={() => setIsShownCompareList(!isShownCompareList)}>
          Сравнить с...
        </Button>
      </Dropdown>
      <Button size="small" type="link" onClick={onSelectToCopy}>
        Копировать
      </Button>
      <Button size="small" className="title__buttons-remove" type="link" onClick={onSelectToDelete}>
        Удалить всё
      </Button>
    </div>
  );
};

export default OntologyBlockButtons;
