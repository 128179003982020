import React, { useMemo } from "react";
import { Collapse, Drawer } from "antd";
import { useSelector } from "react-redux";
import { IdType } from "vis-network";

import { RootState } from "../../store";
import { IEdge } from "../../store/graphs/types";

import Comments from "../comments/Comments";
import LinkedNode from "../LinkedNode";

const { Panel } = Collapse;

type SharedEdgePanelProps = {
  tempDataEdge: IEdge;
  onSelectComment: (model_item_id: string) => void;
  setTempDataEdge: (node: IEdge | undefined) => void;
  onFocus: (id: IdType) => void;
};

const SharedEdgePanel: React.FC<SharedEdgePanelProps> = ({
  tempDataEdge,
  setTempDataEdge,
  onSelectComment,
  onFocus,
}) => {
  const { comments } = useSelector((state: RootState) => state);
  const { nodes, edges } = useSelector((state: RootState) => state.graphs);

  /**
   * Получаем детей выбранного узла
   */
  const childrenLinks = useMemo(() => {
    const selectedEdges = edges
      .filter((edge) => edge.from === tempDataEdge?.id)
      .map((edge) => edge.to);
    return nodes.filter((node) => selectedEdges.includes(node.id));
  }, [edges, nodes, tempDataEdge?.id]);

  /**
   * Получаем родителей выбранного узла
   */
  const parentsLinks = useMemo(() => {
    const selectedEdges = edges
      .filter((edge) => edge.to === tempDataEdge?.id)
      .map((edge) => edge.from);
    return nodes.filter((node) => selectedEdges.includes(node.id));
  }, [edges, nodes, tempDataEdge?.id]);

  //Фильтруем комментарии узлов и связей
  const filteredComments = comments.filter((c) => c.model_item_id === tempDataEdge.model_edge_id);

  return (
    <Drawer
      width={400}
      title="Данные выбранного элемента"
      placement="right"
      onClose={() => setTempDataEdge(undefined)}
      visible={!!tempDataEdge}
      mask={false}
    >
      <Collapse defaultActiveKey={["group_params"]} ghost>
        {parentsLinks.length > 0 && (
          <Panel header={`Родители (${parentsLinks.length})`} key="parents">
            {parentsLinks.map((link) => (
              <LinkedNode key={link.id} link={link} onFocus={onFocus} />
            ))}
          </Panel>
        )}
        {childrenLinks.length > 0 && (
          <Panel header={`Потомки (${childrenLinks.length})`} key="children">
            {childrenLinks.map((link) => (
              <LinkedNode key={link.id} link={link} onFocus={onFocus} />
            ))}
          </Panel>
        )}
        <Panel header={`Комментарии (${filteredComments.length || 0})`} key="comments">
          <Comments comments={filteredComments} onSelect={onSelectComment} />
        </Panel>
      </Collapse>
    </Drawer>
  );
};

export default SharedEdgePanel;
